import { Container, Card, Text, Button, Box, VStack, HStack, Image, Stack } from '@chakra-ui/react'
import moment from 'moment';
import { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

export default function PrintInstallmentReport() {

    const componentRef = useRef();

    const { state } = useLocation();


    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    console.log(state);

    return (
        <>
            <Container maxW='5xl' my='2'>
                <VStack>

                    <Card p='5' w='100%'>
                        <HStack justifyContent='space-between' w='100%'>
                            <Text fontWeight='800'>Installment Report</Text>
                            <Button onClick={handlePrint}>Print</Button>
                        </HStack>
                    </Card>

                    <Box
                        bg='white'
                        ref={componentRef}
                        shadow='md'
                        style={{ height: 'auto', width: '21cm', aspectRatio: '1.41/1', fontSize: 'small' }}
                    >
                        <VStack>

                            <Container
                                bg="white"
                                width='100%' maxW='100%'
                                style={{ height: '29.7cm' }}
                            >

                                <VStack spacing={'3'} mx={4}>

                                    <Box w='100%' p='4' bg='#641344' alignItems='center' display='flex' justifyContent='center'>
                                        <HStack justifyContent='space-between' w='100%'>
                                            <Image border='0px solid #1c1c1c' alt='Logo' src='/logo-white.png' w='250px' />
                                            <Image border='0px solid #1c1c1c' alt='Logo' src='/logo2.png' w='250px' />
                                        </HStack>
                                    </Box>

                                    <VStack spacing={0}>
                                        <Text fontSize='3xl' fontFamily='customfont'>Statement of Accounts</Text>
                                        <Text fontSize='xl' color={'grey'} fontFamily='customfont'>(26/02/2024 to 27/05/2024)</Text>
                                    </VStack>

                                    <Stack direction={{ base: 'column', md: 'row' }} justifyContent='space-between' w='100%' mt={12}>

                                        <VStack alignItems={'start'}>
                                            <Text fontSize='md' color={'#BDBDBD'} >Name: JAGRUTI SHETH</Text>
                                            <Text fontSize='md' color={'#BDBDBD'} >A/C No: 15437896532</Text>
                                        </VStack>

                                        <VStack alignItems={'start'}>
                                            <Text fontSize='md' color={'#BDBDBD'} >Enrollment Date: 26/02/2024</Text>
                                            <Text fontSize='md' color={'#BDBDBD'} >Maturity Date: 26/02/2025</Text>
                                        </VStack>

                                    </Stack>

                                    <Card width={'100%'} mt={4}>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th py='4' fontSize='sm'>Installment No</th>
                                                    <th py='4' fontSize='sm'>Receipt No</th>
                                                    <th py='4' fontSize='sm'>Receipt Date</th>
                                                    <th py='4' fontSize='sm'>Due Date</th>
                                                    <th py='4' fontSize='sm'>Amount</th>
                                                    <th py='4' fontSize='sm'>Status</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td py='4' fontSize='sm'>1</td>
                                                    <td py='4' fontSize='sm'>54321217</td>
                                                    <td py='4' fontSize='sm'>26/02/2024</td>
                                                    <td py='4' fontSize='sm'>24/02/2024</td>
                                                    <td py='4' fontSize='sm'>₹ 5,000</td>
                                                    <td py='4' fontSize='sm'>Paid On Track</td>
                                                </tr>

                                                <tr>
                                                    <td py='4' fontSize='sm'>2</td>
                                                    <td py='4' fontSize='sm'>54321217</td>
                                                    <td py='4' fontSize='sm'>26/02/2024</td>
                                                    <td py='4' fontSize='sm'>24/02/2024</td>
                                                    <td py='4' fontSize='sm'>₹ 5,000</td>
                                                    <td py='4' fontSize='sm'>Paid On Track</td>
                                                </tr>

                                                <tr>
                                                    <td py='4' fontSize='sm'>3</td>
                                                    <td py='4' fontSize='sm'>54321217</td>
                                                    <td py='4' fontSize='sm'>26/02/2024</td>
                                                    <td py='4' fontSize='sm'>24/02/2024</td>
                                                    <td py='4' fontSize='sm'>₹ 5,000</td>
                                                    <td py='4' fontSize='sm'>Paid On Late</td>
                                                </tr>

                                                <tr>
                                                    <td py='4' fontSize='sm'>4</td>
                                                    <td py='4' fontSize='sm'>54321217</td>
                                                    <td py='4' fontSize='sm'>26/02/2024</td>
                                                    <td py='4' fontSize='sm'>24/02/2024</td>
                                                    <td py='4' fontSize='sm'>₹ 5,000</td>
                                                    <td py='4' fontSize='sm'>Paid On Track</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Card>
                                </VStack>
                            </Container>
                        </VStack>
                    </Box>
                </VStack>
            </Container>
        </>
    )
}
import { Container, Card, Text, Image, VStack } from "@chakra-ui/react";
import React from 'react'

export default function LocationDasboard() {
  return (
    <Container my='2' maxW='8xl'>

      <VStack> 

        <Image w='500px' src='/home.jpg' />

      </VStack>
    </Container>
  )
}

import React from 'react';
import { useAuth } from '../../providers/AuthProvider';
import AdminDashboard from '../dashboards/AdminDashboard';
import LocationDasboard from '../dashboards/LocationDasboard';

export default function Dashboard() {

    const { user } = useAuth();

    return (
        <>
            {user.type.toLowerCase() === "admin" ?
                <AdminDashboard />
                : <LocationDasboard />}
        </>
    )
}

import {
  Container,
  VStack,
  Card,
  HStack,
  Button,
  Center,
  Text,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { useScheme } from '../../providers/SchemeProvider';
import { useAuth } from '../../providers/AuthProvider';
import { ConfirmDialog } from '../utils/ConfirmDialog';

export default function Schemes() {

  var { scheme, deleteData } = useScheme();
  const { user } = useAuth();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(-1);

  const handleConfirm = async () => {
    try {
      await deleteData(selectedId);
      setIsOpen(false);
    } catch (error) {

    }
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Container p='4' maxW='4xl'>
      <VStack width='100%' spacing={4}>

        <Card borderRadius='md' p='4' width={'100%'}>
          <HStack justifyContent='space-between'>
            <Text fontWeight='bold' className='customfont' fontSize='2xl'>Schemes</Text>

            {user.type.toLowerCase() === "admin" ?
              <Link to={'/scheme/add'}> <Button bg='primary.800' colorScheme='primary'>Add</Button> </Link>
              : <></>}

          </HStack>
        </Card>

        <Card width={'100%'}>

          <table>
            <thead>
              <tr>
                <th py='4' fontSize='sm'>Name</th>
                <th py='4' fontSize='sm'>Monthly Price</th>
                <th py='4' fontSize='sm'>Months</th>
                <th py='4' fontSize='sm'>Total Investment</th>
                <th py='4' fontSize='sm'>Total Return</th>
                {/* <th py='4' fontSize='sm'>Return Percent</th> */}
                {user.type.toLowerCase() === "admin" ?
                  <th py='4' fontSize='sm'>Action</th>
                  : <></>}
              </tr>
            </thead>

            <tbody>
              {scheme.map((s, idx) => {
                return (
                  <tr key={idx}>
                    <td py='4' fontSize='sm'> {s.name}</td>
                    <td py='4' fontSize='sm'> {s.monthly_price}</td>
                    <td py='4' fontSize='sm'> {s.months}</td>
                    <td py='4' fontSize='sm'> {s.total_investment}</td>
                    <td py='4' fontSize='sm'> {s.total_return}</td>
                    {/* <td py='4' fontSize='sm'> {s.return_percent}</td> */}
                    {user.type.toLowerCase() === "admin" ?
                      <td py='4' fontSize='sm' >
                        <Center>
                          <HStack >
                            <Link to={`/scheme/edit`} state={s}>
                              <Button size="sm" colorScheme='green'>Update</Button>
                            </Link>

                            <Button size="sm" onClick={() => { setSelectedId(s.id); setIsOpen(true); }} colorScheme='red'>
                              Delete
                            </Button>
                          </HStack>
                        </Center>
                      </td>
                      : <></>}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Card>
      </VStack>

      <ConfirmDialog
        isOpen={isOpen}
        onClose={handleClose}
        onConfirm={handleConfirm}
        title="Are you sure?"
        body="This action cannot be undone."
      />
    </Container>
  )
}




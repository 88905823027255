import { Container, Card, Text, Button, Box, VStack, HStack, Image, Divider } from '@chakra-ui/react'
import moment from 'moment';
import { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { ToWords } from 'to-words';

export default function PrintPaymentReceipt() {

    const componentRef = useRef();
    const toWords = new ToWords();

    const { state } = useLocation();


    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    console.log(state);

    return (
        <>
            <Container maxW='5xl' my='2'>
                <VStack>

                    <Card p='5' w='100%'>
                        <HStack justifyContent='space-between' w='100%'>
                            <Text fontWeight='800'>Payment Report</Text>
                            <Button onClick={handlePrint}>Print</Button>
                        </HStack>
                    </Card>

                    <Box
                        bg='white'
                        ref={componentRef}
                        shadow='md'
                        style={{ height: 'auto', width: '21cm', aspectRatio: '1.41/1', fontSize: 'small' }}
                    >

                        <VStack w='100%'>

                            <Container
                                bg="white"
                                width='100%' maxW='100%'
                                style={{ height: '29.7cm' }}
                            >

                                <VStack spacing={0} mx={4}>

                                    <Image border='0px solid #1c1c1c' alt='Logo' src='/logo192.png' w='250px' />

                                    <Divider borderColor="black" />

                                    <HStack justifyContent='space-between' w='100%' mb={6} mt={2}>

                                        <Text fontSize={'lg'} fontWeight={600}>Payment Receipt</Text>

                                        <Text fontSize={'lg'} fontWeight={600}>Customer Copy</Text>

                                    </HStack>

                                    <Divider borderColor="black" />

                                    <HStack w='100%' justifyContent={'space-between'} alignItems={'start'} mt={4} mb={6} spacing='4'>
                                        <HStack w='100%'>
                                            <VStack spacing={0} alignItems={'start'}>
                                                <VStack spacing={0} alignItems={'start'}>
                                                    <Text fontSize={'sm'} fontWeight={700}>LIMELIGHT LAB GROWN DIAMONDS LTD</Text>
                                                    <Text fontSize={'sm'} fontWeight={500}> 601, 6th Floor, DLH Plaza Cosmos, Swami Vivekananda Rd, opposite Shoppers Stop, Bharucha Baug, Parsi Colony,</Text>
                                                </VStack>

                                                <Text fontSize={'sm'} fontWeight={500}>Mumbai 400058</Text>
                                                <Text fontSize={'sm'} fontWeight={500}>MAHARASHTRA</Text>
                                                <Text fontSize={'sm'} fontWeight={500}>Tel: 99203 16661</Text>
                                                <Text fontSize={'sm'} fontWeight={500}>GSTIN : 27AACCO5456D1ZL </Text>
                                            </VStack>
                                        </HStack>

                                        <HStack w='100%' >
                                            <VStack spacing={0} alignItems={'start'}>
                                                <Text fontSize={'sm'} fontWeight={700} >Name of the account holder : {state.membership.name}</Text>
                                                <Text fontSize={'sm'} fontWeight={500}>Scheme : {state.membership.scheme_name}</Text>
                                                <Text fontSize={'sm'} fontWeight={500}>Membership ID : {state.membership.id_number}</Text>
                                                <Text fontSize={'sm'} fontWeight={500}>Enrollment Date : {moment(state.membership.enrollment_date).format("DD-MM-YYYY")}</Text>
                                                <Text fontSize={'sm'} fontWeight={500}>Mobile No : {state.membership.phone}</Text>
                                                <Text fontSize={'sm'} fontWeight={500}>Email Id : {state.membership.e_mail}</Text>
                                            </VStack>
                                        </HStack>

                                    </HStack>

                                    <Divider borderColor="black" />

                                    <Card width={'100%'} my={4}>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th py='4' fontSize='sm'>Receipt Date</th>
                                                    <th py='4' fontSize='sm'>Receipt No</th>
                                                    <th py='4' fontSize='sm'>Installment No</th>
                                                    <th py='4' fontSize='sm'>Mode of payment</th>
                                                    <th py='4' fontSize='sm'>Amount (INR)</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td py='4' fontSize='sm'>{moment(state.term.user_payment_date).format("DD-MM-YYYY")}</td>
                                                    <td py='4' fontSize='sm'>LLIS00{state.term.id}</td>
                                                    <td py='4' fontSize='sm'>{state.term.term}</td>
                                                    <td py='4' fontSize='sm'>{state.term.payment_mode}</td>
                                                    <td py='4' fontSize='sm'>{state.term.amount}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Card>

                                    <Divider borderColor="black" />

                                    <Text w='100%' alignItems={'start'} fontSize={'sm'} fontWeight={500} my={2}>Total Amount Paid : INR {state.term.amount}</Text>
                                    <Text w='100%' alignItems={'start'} fontSize={'sm'} fontWeight={500} my={1}>Total In Words : {toWords.convert(state.term.amount, { currency: true })}</Text>

                                    <Text fontWeight={500} my={2} w='100%' alignItems={'start'} fontSize={'sm'}>Cumulative Balance in account as on :- INR {state.term.term * state.term.amount}</Text>

                                    <Text my={1} w='100%' alignItems={'start'} fontSize={'xs'}>Terms & Conditions Applied.</Text>

                                    <Divider borderColor="black" />

                                    <VStack w='100%' spacing={24} my={2}>
                                        <Text w='100%' alignItems={'start'} fontSize={'sm'} fontWeight={500}>For LIMELIGHT LAB GROWN DIAMONDS LTD</Text>
                                        <VStack w='100%'>
                                            <Text w='100%' alignItems={'start'} fontSize={'sm'} fontWeight={500}>Store Manager</Text>
                                            <Text w='100%' alignItems={'start'} fontSize={'sm'} fontWeight={500}>{state.membership.branch}</Text>
                                        </VStack>
                                    </VStack>

                                    <Divider borderColor="black" />

                                    <VStack w='100%' spacing={2} my={4}>
                                        <Text w='100%' alignItems={'start'} fontSize={'xs'} fontWeight={600}>Corporate Office : 601, 6th Floor, DLH Plaza Cosmos, Swami Vivekananda Rd, opposite Shoppers Stop, Bharucha Baug, Parsi Colony,</Text>
                                        <Text w='100%' alignItems={'start'} fontSize={'xs'} fontWeight={600}>Andheri West, Mumbai, Maharashtra 40005. , Tel +91 99203 16661 </Text>
                                        <Text w='100%' alignItems={'start'} fontSize={'xs'} fontWeight={600}>Email - customer@limelightdiamonds.com</Text>
                                        <Text w='100%' alignItems={'start'} fontSize={'xs'} fontWeight={600}>Website - www.limelightdiamonds.com </Text>
                                    </VStack>

                                </VStack>

                            </Container>

                        </VStack>
                    </Box>
                </VStack>
            </Container>
        </>
    )
}
import React from 'react'
import {
    Box,
    Button,
    HStack,
    useDisclosure,
    Flex,
    IconButton,
    Stack,
    Image,
} from '@chakra-ui/react'
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons'
import { ToastContainer } from 'react-toastify';
import { BiExit } from 'react-icons/bi'
import NavLinks from './NavLinks'
import { useAuth } from '../../providers/AuthProvider'
import 'react-toastify/dist/ReactToastify.css'
import { Link } from 'react-router-dom';

export default function Navbar() {

    const { isOpen, onOpen, onClose } = useDisclosure();
    const { logout, user } = useAuth();

    return (
        <>
            <Box bg='white' px={4}>
                <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
                    <IconButton
                        size={'md'}
                        icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                        aria-label={'Open Menu'}
                        display={{ md: 'none' }}
                        onClick={isOpen ? onClose : onOpen}
                    />
                    <HStack spacing={5} alignItems={'center'}>

                        <HStack as={'nav'} spacing={2} display={{ base: 'none', md: 'flex' }}>

                            <Link to='/'>
                                <Image mx='2' src="/logo192.png" width='180px' alt="Logo" />
                            </Link>

                            <NavLinks />

                        </HStack>
                    </HStack>
                    <Flex alignItems={'center'}>
                        <Button
                            onClick={logout}
                            variant={'solid'}
                            colorScheme={'red'}
                            size={'md'}
                            mr={4}
                            borderRadius='md'
                            rightIcon={<BiExit />}>
                            Logout {user.username} {user.type}
                        </Button>
                    </Flex>
                </Flex>

                {isOpen ? (
                    <Box pb={4} display={{ md: 'none' }}>
                        <Stack as={'nav'} spacing={4}>

                            <NavLinks />

                        </Stack>
                    </Box>
                ) : null}
            </Box>

            <ToastContainer />
        </>
    )
}

import { Container, Card, Text, Image, VStack, SimpleGrid } from "@chakra-ui/react";
import React from 'react'

export default function AdminDashboard() {
  return (
    <Container my='2' maxW='8xl'>

      {/* <Text className="customfont" fontSize='2xl'>Welcome Admin</Text>

      <SimpleGrid>

      </SimpleGrid> */}

      <VStack>
        <Image w='500px' src='/home.jpg' />
      </VStack>
    </Container>
  )
}

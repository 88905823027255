import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useAuth } from "./AuthProvider";
import { url } from "../config";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

const SchemeContext = createContext();

const SchemeProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const { token, logged, logout } = useAuth();
    const navigate = useNavigate();

    const [scheme, setScheme] = useState([]);

    async function addData(name, monthly_price, months, total_investment, total_return, return_percent) {
        console.log(name, monthly_price, months, total_investment, total_return, return_percent)
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            await axios.post(`${url}/admin/schemes`,
                {
                    name,
                    monthly_price,
                    months,
                    total_investment,
                    total_return,
                    return_percent
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + token
                    },
                },
            );
            await getAll();
            navigate("/schemes");
            toast.success("Added !");
        } catch (error) {
            console.error(error);
            if (error.response.status === 400) {
                toast.error("Scheme Already Exists");
            }
            return "err";
        }
    }

    async function updateData(id, name, monthly_price, months, total_investment, total_return, return_percent) {
        console.log("Update Scheme");
        console.log(id, name, monthly_price, total_investment, total_return, return_percent);
        setLoading(true);
        // console.log(token);
        if (!logged) {
            return;
        }
        try {
            var { data } = await axios.put(`${url}/admin/schemes`,
                {
                    id,
                    name,
                    monthly_price,
                    months,
                    total_investment,
                    total_return,
                    return_percent
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + token
                    },
                },
            );
            console.log(data);
            await getAll();
            navigate("/schemes");
            toast.success("Updated !");
        } catch (error) {
            if (error.response.status === 400) {
                toast.error("Scheme Already Exists");
            }
            return "err";
        }
    }

    async function deleteData(id) {
        console.log(id)
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            await axios.delete(`${url}/admin/schemes`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                data: {
                    "id": id,
                },
            }
            );
            await getAll();
            toast.success("DELETED Successfully !");
        } catch (error) {
            console.error(error);
            toast.error("Error Deleting !");
            return "err";
        }
    }

    async function getAll() {
        setLoading(true);

        if (!logged) {
            return;
        }
        try {
            var { data } = await axios.get(`${url}/schemes`,
                {
                    headers: {
                        Authorization: 'Bearer ' + token
                    },
                },
            );
            setScheme(data.data);
            setLoading(false);
        } catch (error) {
            console.error(error);
            if (error.response.status === 401) {
                console.error("NOT AUTHENTICATED");
                logout();
            }
            return "err";
        }
    }


    useEffect(() => {
        getAll();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            loading,
            scheme,
            addData,
            updateData,
            deleteData,
        }),
        [scheme, logged, loading] // eslint-disable-line react-hooks/exhaustive-deps
    );

    // Provide the Schemecontext context to the children components
    return (
        <SchemeContext.Provider value={contextValue}>{children}</SchemeContext.Provider>
    );
};

export const useScheme = () => {
    return useContext(SchemeContext);
};

export default SchemeProvider;
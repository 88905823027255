import {
    Container,
    VStack,
    Card,
    HStack,
    Text,
} from '@chakra-ui/react'
import React from 'react'
import AddUserForm from './AddUserForm';

export default function AddUser() {
    return (
        <Container p='4' maxW='3xl'>
            <VStack width='100%' spacing={4}>

                <Card borderRadius='md' p='4' width={'100%'}>
                    <HStack justifyContent='space-between'>
                        <Text fontWeight='bold' className='customfont' fontSize='2xl'>Add New Member</Text>
                    </HStack>
                </Card>

                <AddUserForm />

            </VStack>
        </Container>
    )
}



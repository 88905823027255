import {
    Container,
    Card,
    Text,
    VStack,
    HStack,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    FormControl,
    FormLabel,
    Radio,
    Stack,
    RadioGroup,
    Row,
} from "@chakra-ui/react";
import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
import { useLocation } from 'react-router-dom';
import { useMemberShip } from "../../providers/MemberShipProvider";
import { Link } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";

export default function MemberShipInstallments() {

    const { state } = useLocation();

    const { installments, getInstallments, acceptStorePayment } = useMemberShip();
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [term, setTerm] = useState(-1);

    const formik = useFormik({
        initialValues: {
            payment_mode: "COD",
        },
        onSubmit: async (values) => {
            console.log(state);
            let submitFields = {
                id: state.id,
                term: term,
                payment_mode: values.payment_mode,
                date: moment().format("YYYY-MM-DD"),
            };
            onClose();
            console.log(submitFields);
            try {
                await acceptStorePayment(submitFields);
            } catch (error) {
                toast.error("Error Accepting Payment !");
            }

        }
    });

    useEffect(() => {
        getInstallments(state.id);
    }, [state])

    return (
        <>
            <Container maxW='6xl' my='4'>
                <VStack>
                    <Card borderRadius='md' p='4' width={'100%'}>
                        <VStack w='100%'>
                            <HStack w='100%' justifyContent='space-between'>

                                <Text fontWeight='bold' className='customfont' fontSize='2xl'>Membership Installments</Text>

                            </HStack>

                            <VStack width='100%' spacing={4} alignItems='start'>
                                <Text>Name : {state.name}</Text>
                                <Text>Membership ID : {state.id_number}</Text>
                                <Text>Phone : {state.phone}</Text>
                                <Text>Address : {state.address}</Text>
                            </VStack>
                        </VStack>
                    </Card>


                    <Card width={'100%'}>

                        <table>
                            <thead>
                                <tr>
                                    <th py='4' fontSize='sm'>Term</th>
                                    <th py='4' fontSize='sm'>Amount</th>
                                    <th py='4' fontSize='sm'>User Paid</th>
                                    <th style={{ width: '160px' }} py='4' fontSize='sm'>Store Payment Status</th>
                                    <th style={{ width: '160px' }} py='4' fontSize='sm'>Payment Mode</th>
                                    <th style={{ width: '160px' }} py='4' fontSize='sm'>Payment Date</th>
                                    <th style={{ width: '160px' }} py='4' fontSize='sm'>Action</th>

                                </tr>
                            </thead>

                            <tbody>
                                {installments.map((i, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <td py='4' fontSize='sm'>{i.term}</td>
                                            <td py='4' fontSize='sm'> {i.amount}</td>
                                            <td py='4' fontSize='sm'> {i.paid_by_user ? 'Yes' : 'No'}</td>
                                            <td py='4' fontSize='sm'> {i.accepted_by_store ? 'Yes' : 'No'}</td>
                                            <td py='4' fontSize='sm'> {i.payment_mode}</td>
                                            <td py='4' fontSize='sm'>
                                                {i.user_payment_date == null ? "" : moment(i.user_payment_date).format('DD-MM-YYYY')}
                                            </td>
                                            <td py='4' fontSize='sm'>
                                                <VStack>
                                                    {i.accepted_by_store ?
                                                        <Button w='98%' colorScheme="whatsapp">
                                                            <Link to='/users/memberships/installments/print' state={{ membership: state, term: i }}>
                                                                Print Receipt
                                                            </Link>
                                                        </Button> : <>

                                                            {/* {(installments[i.term - 2].accepted_by_store ) ? */}
                                                            <Button colorScheme="purple" w='98%' onClick={() => {
                                                                setTerm(i.term);
                                                                onOpen();
                                                            }}>
                                                                Accept Payment
                                                            </Button>
                                                            {/* : <></> */}
                                                            {/* } */}
                                                        </>
                                                    }
                                                </VStack>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </Card>

                    {/* <SimpleGrid columns={[1, 1, 2, 4]} spacing='4'>

                    {installments.map((i, idx) => {
                        return (
                            <Card key={idx} bg={i.accepted_by_store ? 'green.100' : i.paid_by_user ? 'yellow.100' : 'gray.100'} borderRadius='md' p='4' width={'100%'}>
                                <VStack w='100%'>
                                    <VStack w='100%' justifyContent='start' alignItems='start'>
                                        <Text>Term : {i.term}</Text>
                                        <Text>Amount : {i.amount}</Text>
                                        <Text>Paid by User: {i.paid_by_user ? 'Yes' : 'No'}</Text>
                                        <Text>Confirmed By Store: {i.accepted_by_store ? 'Yes' : 'No'}</Text>
                                        <VStack alignItems='start'>
                                            {(i.paid_by_user && !i.accepted_by_store) &&
                                                <Button colorScheme="whatsapp">Accept Payment</Button>
                                            }
                                            {(!i.paid_by_user) &&
                                                <Button colorScheme="red">Mark Paid</Button>
                                            }
                                        </VStack>
                                    </VStack>
                                </VStack>
                            </Card>
                        )
                    })}
                </SimpleGrid> */}

                </VStack>
            </Container>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Accept Payment</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <form onSubmit={formik.handleSubmit}>
                            <VStack alignItems='start' spacing='6'>
                                <FormControl>
                                    <FormLabel>Payment Mode</FormLabel>
                                    <RadioGroup
                                        onChange={(e) => {
                                            formik.setFieldValue('payment_mode', e);
                                        }}
                                        value={formik.values.payment_mode}
                                    >
                                        <Stack direction='row'>
                                            <Radio _selected={formik.values.payment_mode == "COD"} value='COD'>COD</Radio>
                                            <Radio _selected={formik.values.payment_mode == "Online"} value='Online'>Online</Radio>
                                        </Stack>
                                    </RadioGroup>
                                </FormControl>
                                <HStack>
                                    <Button type="submit" colorScheme="whatsapp">Accept</Button>
                                    <Button onClick={onClose} colorScheme="red">Close</Button>
                                </HStack>
                            </VStack>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>

        </>
    )
}

import {
    VStack,
    Card,
    Button,
    FormControl,
    FormLabel,
    Input,
    HStack,
    Text,
    Box,
    Checkbox,
    Radio,
    RadioGroup,
    Textarea,
    Center,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    CircularProgress,
    ModalCloseButton,
    useDisclosure,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useUsers } from '../../providers/UsersProvider';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useScheme } from '../../providers/SchemeProvider';
import { useAuth } from '../../providers/AuthProvider';
import moment from 'moment';

export default function AddUserForm() {

    var { addUser, getUserWithSamePhone } = useUsers();
    const { user } = useAuth();
    const { scheme } = useScheme();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            id_number: "",
            mobile_no: "",
            enrolmnt_date: "",
            expiry_date: "",
            name: "",
            dob: "",
            gender: "",
            email: "",
            id_proof: "",
            nominee_name: "",
            nominee_relationship: "",
            nominee_phone: "",
            nominee_id_proof: "",
            nominee_id_no: "",
            address: "",
            city: "",
            pincode: "",
            id_no: "",
            enrolled_by: "",
            authourised_store: "",
            auth_id_no: "",
            plan_selected: "",
        },
        onSubmit: async (values) => {

            let payment_date = moment(values.enrolmnt_date).get('D');
            // console.log(Number.parseInt(payment_date));

            //phone validation
            const mobileNo = values.mobile_no.toString();
            const nomineeMobileNo = values.nominee_phone.toString();
            if (mobileNo.length !== 10 || nomineeMobileNo.length !== 10) {
                toast.error("Phone No should be 10 digit");
                return;
            }

            //user id proof validation
            if (values.id_proof === 'AADHAR' || values.id_proof === 'PAN CARD') {
                if (values.id_proof === 'AADHAR') {
                    if (values.id_no.length != 12) {
                        toast.error("Adhaar No should be 12 digit");
                        return;
                    }
                }
                else if (values.id_proof === 'PAN CARD') {
                    if (values.id_no.length != 10) {
                        toast.error("PAN No should be 10 digit");
                        return;
                    }
                }
            } else {
                toast.error("Please select user id proof!");
                return;
            }

            //nominee id proof validation
            if (values.nominee_id_proof === 'AADHAR' || values.nominee_id_proof === 'PAN CARD') {
                if (values.nominee_id_proof === 'AADHAR') {
                    if (values.nominee_id_no.length != 12) {
                        toast.error("Adhaar No should be 12 digit");
                        return;
                    }
                }
                else if (values.nominee_id_proof === 'PAN CARD') {
                    if (values.nominee_id_no.length != 10) {
                        toast.error("PAN No should be 10 digit");
                        return;
                    }
                }
            } else {
                toast.error("Please select nominee id proof!");
                return;
            }

            if (Number.parseInt(payment_date) > 28) {
                payment_date = "28";
                toast.info("Payment Date is set to 28 to avoid conflicts !");
                // return;
            }

            if (values.plan_selected === "") {
                toast.error("Please select a plan!");
                return;
            }

            var selectedPlan;
            for (var p of scheme) {
                // console.log(p);
                if (p.id == values.plan_selected) {
                    selectedPlan = p;
                    break;
                }
            }

            try {
                onOpen();
                console.log(user);
                let dataToAdd = {
                    "location": user.id,
                    "name": values.name,
                    "id_number": "",
                    "user_id_proof": values.id_proof,
                    "user_id_no": values.id_no,
                    "enrolled_by": values.enrolled_by,
                    "enrollment_date": values.enrolmnt_date,
                    "expiry_date": values.expiry_date,
                    "date_of_birth": moment(values.dob).format("YYYY-MM-DD"),
                    "gender": values.gender,
                    "phone": values.mobile_no,
                    "address": values.address,
                    // "state": '',
                    "city": values.city,
                    "pincode": values.pincode,
                    "e_mail": values.email,
                    "scheme_name": selectedPlan.name,
                    "monthly_price": selectedPlan.monthly_price,
                    "installments": selectedPlan.months,
                    "payment_date": payment_date,
                    "branch": user.name,
                    "nominee_name": values.nominee_name,
                    "nominee_relationship": values.nominee_relationship,
                    "nominee_gender": values.nominee_gender,
                    "nominee_phone": values.nominee_phone,
                    "nominee_address": values.nominee_address,
                    "nominee_email": values.nominee_email,
                    "nominee_id_proof": values.nominee_id_proof,
                    "nominee_id_no": values.nominee_id_no,
                };

                var res = await addUser(dataToAdd);

                navigate("/users/print", { state: res.data });
                console.log(dataToAdd);
            } catch (error) {
                console.log(error);
                toast.error("Error Adding !");
            } finally {
                onClose();
            }
        }
    });

    return (
        <>
            <Card width={'100%'} p='6'>
                <form onSubmit={formik.handleSubmit}>
                    <VStack spacing={4} align="flex-start">

                        <HStack>

                            <FormControl>
                                <HStack w={'100%'}>
                                    <FormLabel className='customfont' fontSize='md' htmlFor="enrolmnt_date" w={'35%'}>
                                        ENROLLMENT DATE:
                                    </FormLabel>
                                    <Input
                                        id="enrolmnt_date"
                                        name="enrolmnt_date"
                                        type="date"
                                        variant="filled"
                                        required={true}
                                        onkeydown="return false"
                                        onChange={(e) => {
                                            // console.log(e.target.value);
                                            let selectedDate = moment(e.target.valueAsDate, "DD-MM-YYYY");
                                            // console.log(selectedDate.format("DD-MM-YYYY"));
                                            let expiryDate = moment(selectedDate, "DD-MM-YYYY").add(400, 'days');
                                            // console.log(expiryDate.format("DD-MM-YYYY"));
                                            formik.setFieldValue('enrolmnt_date', e.target.value);
                                            formik.setFieldValue('expiry_date', expiryDate.format("yyyy-MM-DD"));
                                        }}
                                        value={formik.values.enrolmnt_date}
                                    />
                                </HStack>
                            </FormControl>


                            <FormControl>
                                <HStack w={'100%'} spacing='1'>
                                    <VStack w={'100%'} alignItems={'start'} spacing={0}>
                                        <FormLabel className='customfont' fontSize='md' htmlFor="expiry_date" >
                                            EXPIRY DATE:
                                        </FormLabel>
                                        <Text fontSize={'10px'} fontWeight={'700'}>
                                            (400 Days from enrollment)
                                        </Text>
                                    </VStack>
                                    <Input
                                        id="expiry_date"
                                        name="expiry_date"
                                        type="date"
                                        variant="filled"
                                        required={true}
                                        disabled={true}
                                        // onChange={formik.handleChange}
                                        value={formik.values.expiry_date}
                                    />
                                </HStack>
                            </FormControl>

                        </HStack>

                        <Card borderRadius='md' width={'100%'} bg='primary.800' colorScheme='primary'>
                            <Text fontWeight='500' className='customfont' p={3} fontSize='lg' color={'white'}>Personal Information</Text>
                        </Card>

                        <FormControl>
                            <HStack w={'100%'}>
                                <FormLabel htmlFor="mobile_no" w={'30%'}>Mobile Number:</FormLabel>
                                <Input
                                    id="mobile_no"
                                    name="mobile_no"
                                    type="number"
                                    variant="filled"
                                    maxLength="10"
                                    required={true}
                                    // onChange={formik.handleChange}
                                    onChange={async (e) => {
                                        let value = e.target.value;
                                        // console.log(value);
                                        formik.setFieldValue('mobile_no', e.target.value);
                                        if (value.length == 10) {
                                            // console.log("GET DATA");
                                            var usersWithPhone = await getUserWithSamePhone(value);
                                            // console.log(usersWithPhone);
                                            if (usersWithPhone > 0) {
                                                toast.info("A Member already Exists with this phone Number",
                                                    { position: 'top-center', closeButton: true, }
                                                );
                                            }
                                        }

                                    }}
                                    value={formik.values.mobile_no}
                                />
                            </HStack>
                        </FormControl>

                        <FormControl>
                            <HStack w={'100%'}>
                                <FormLabel htmlFor="name" w={'30%'}>Name:</FormLabel>
                                <Input
                                    id="name"
                                    name="name"
                                    type="text"
                                    variant="filled"
                                    required={true}
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                />
                            </HStack>
                        </FormControl>

                        <FormControl>
                            <HStack w={'100%'}>
                                <FormLabel htmlFor="dob" w={'30%'}>
                                    Date of Birth:
                                </FormLabel>
                                <Input
                                    id="dob"
                                    name="dob"
                                    type="date"
                                    variant="filled"
                                    required={true}
                                    onChange={formik.handleChange}
                                    value={formik.values.dob}
                                />
                            </HStack>
                        </FormControl>

                        <FormControl>
                            <HStack w={'100%'}>
                                <FormLabel htmlFor="gender" w={'150px'}>
                                    Gender:
                                </FormLabel>
                                <RadioGroup
                                    name="gender"
                                    value={formik.values.gender}
                                    onChange={formik.handleChange}
                                    w={'70%'}
                                >
                                    <Radio
                                        value="Male"
                                        size='lg'
                                        colorScheme='primary'
                                        checked={formik.values.gender === 'Male'}
                                        onChange={formik.handleChange}
                                    >
                                        Male
                                    </Radio>
                                    <Box display='inline-block' p='2' />
                                    <Radio
                                        value="Female"
                                        size='lg'
                                        colorScheme='primary'
                                        checked={formik.values.gender === 'Female'}
                                        onChange={formik.handleChange}
                                    >
                                        Female
                                    </Radio>
                                </RadioGroup>
                            </HStack>
                        </FormControl>

                        <FormControl>
                            <HStack w={'100%'}>
                                <FormLabel htmlFor="address" w={'30%'}>Address:</FormLabel>
                                <Textarea
                                    id="address"
                                    name="address"
                                    type="text"
                                    maxLength="100"
                                    variant="filled"
                                    required={true}
                                    onChange={formik.handleChange}
                                    value={formik.values.address}
                                    rows={2}
                                />
                            </HStack>
                        </FormControl>

                        <FormControl>
                            <HStack w={'100%'}>
                                <FormLabel htmlFor="city" w={'30%'}>City:</FormLabel>
                                <Input
                                    id="city"
                                    name="city"
                                    type="text"
                                    maxLength="50"
                                    variant="filled"
                                    required={true}
                                    onChange={formik.handleChange}
                                    value={formik.values.city}
                                />
                            </HStack>
                        </FormControl>

                        <FormControl>
                            <HStack w={'100%'}>
                                <FormLabel htmlFor="pincode" w={'30%'}>Pin Code:</FormLabel>
                                <Input
                                    id="pincode"
                                    name="pincode"
                                    maxLength="6"
                                    type="number"
                                    variant="filled"
                                    required={true}
                                    onChange={formik.handleChange}
                                    value={formik.values.pincode}
                                />
                            </HStack>
                        </FormControl>

                        <FormControl>
                            <HStack w={'100%'}>
                                <FormLabel htmlFor="id_proof" w={'150px'}>
                                    ID PROOF:
                                </FormLabel>
                                <RadioGroup
                                    name="id_proof"
                                    value={formik.values.id_proof}
                                    onChange={formik.handleChange}
                                    w={'70%'}
                                >
                                    <Radio
                                        value="AADHAR"
                                        size='lg'
                                        colorScheme='primary'
                                        checked={formik.values.id_proof === 'AADHAR'}
                                        onChange={formik.handleChange}
                                    >
                                        Adhaar Card
                                    </Radio>
                                    <Box display='inline-block' p='2' />
                                    <Radio
                                        value="PAN CARD"
                                        size='lg'
                                        colorScheme='primary'
                                        checked={formik.values.id_proof === 'PAN CARD'}
                                        onChange={formik.handleChange}
                                    >
                                        Pan Card
                                    </Radio>
                                </RadioGroup>
                            </HStack>
                        </FormControl>

                        <FormControl>
                            <HStack w={'100%'}>
                                <FormLabel htmlFor="id_no" w={'30%'}>ID No:</FormLabel>
                                <Input
                                    id="id_no"
                                    name="id_no"
                                    type="text"
                                    variant="filled"
                                    required={true}
                                    onChange={formik.handleChange}
                                    value={formik.values.id_no}
                                />
                            </HStack>
                        </FormControl>

                        <FormControl>
                            <HStack w={'100%'}>
                                <FormLabel htmlFor="enrolmnt_by" w={'30%'}>ENROLLED BY:</FormLabel>
                                <Input
                                    id="enrolled_by"
                                    name="enrolled_by"
                                    type="text"
                                    variant="filled"
                                    maxLength="50"
                                    required={true}
                                    onChange={formik.handleChange}
                                    value={formik.values.enrolled_by}
                                />
                            </HStack>
                        </FormControl>


                        <FormControl>
                            <HStack w={'100%'}>
                                <FormLabel htmlFor="email" w={'30%'}>Email ID:</FormLabel>
                                <Input
                                    id="email"
                                    name="email"
                                    type="email"
                                    maxLength="56"
                                    variant="filled"
                                    required={true}
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                />
                            </HStack>
                        </FormControl>

                        <FormControl>
                            <HStack w={'100%'}>
                                <FormLabel htmlFor="nominee_name" w={'30%'}>Nominee Name:</FormLabel>
                                <Input
                                    id="nominee_name"
                                    name="nominee_name"
                                    maxLength="100"
                                    type="text"
                                    variant="filled"
                                    required={true}
                                    onChange={formik.handleChange}
                                    value={formik.values.nominee_name}
                                />
                            </HStack>
                        </FormControl>

                        <FormControl>
                            <HStack w={'100%'}>
                                <FormLabel htmlFor="nominee_relationship" w={'30%'}>
                                    Relationship with Account Holder:
                                </FormLabel>
                                <Input
                                    id="nominee_relationship"
                                    name="nominee_relationship"
                                    maxLength="50"
                                    type="text"
                                    variant="filled"
                                    required={true}
                                    onChange={formik.handleChange}
                                    value={formik.values.nominee_relationship}
                                />
                            </HStack>
                        </FormControl>

                        <FormControl>
                            <HStack w={'100%'}>
                                <FormLabel htmlFor="nominee_phone" w={'30%'}>Mobile No:</FormLabel>
                                <Input
                                    id="nominee_phone"
                                    name="nominee_phone"
                                    type="number"
                                    maxLength="10"
                                    variant="filled"
                                    required={true}
                                    onChange={formik.handleChange}
                                    value={formik.values.nominee_phone}
                                />
                            </HStack>
                        </FormControl>

                        <FormControl>
                            <HStack w={'100%'}>
                                <FormLabel htmlFor="nominee_id_proof" w={'150px'}>
                                    ID PROOF:
                                </FormLabel>
                                <RadioGroup
                                    name="nominee_id_proof"
                                    value={formik.values.nominee_id_proof}
                                    onChange={formik.handleChange}
                                    w={'70%'}
                                >
                                    <Radio
                                        value="AADHAR"
                                        size='lg'
                                        colorScheme='primary'
                                        checked={formik.values.nominee_id_proof === 'AADHAR'}
                                        onChange={formik.handleChange}
                                    >
                                        Adhaar Card
                                    </Radio>
                                    <Box display='inline-block' p='2' />
                                    <Radio
                                        value="PAN CARD"
                                        size='lg'
                                        colorScheme='primary'
                                        checked={formik.values.nominee_id_proof === 'PAN CARD'}
                                        onChange={formik.handleChange}
                                    >
                                        Pan Card
                                    </Radio>
                                </RadioGroup>
                            </HStack>
                        </FormControl>

                        <FormControl>
                            <HStack w={'100%'}>
                                <FormLabel htmlFor="nominee_id_no" w={'30%'}>ID No:</FormLabel>
                                <Input
                                    id="nominee_id_no"
                                    name="nominee_id_no"
                                    type="text"
                                    variant="filled"
                                    required={true}
                                    onChange={formik.handleChange}
                                    value={formik.values.nominee_id_no}
                                />
                            </HStack>
                        </FormControl>

                        <Card borderRadius='md' width={'100%'} bg='primary.800' colorScheme='primary'>
                            <Text fontWeight='500' className='customfont' p={3} fontSize='lg' color={'white'}>Select Scheme</Text>
                        </Card>

                        <table>
                            <thead>
                                <tr>
                                    <th py='4' fontSize='sm'>Select</th>
                                    <th py='4' fontSize='sm'>Name</th>
                                    <th py='4' fontSize='sm'>Monthly Price</th>
                                    <th py='4' fontSize='sm'>Months</th>
                                    <th py='4' fontSize='sm'>Total Investment</th>
                                    <th py='4' fontSize='sm'>Total Return</th>
                                    <th py='4' fontSize='sm'>Return Percent</th>
                                </tr>
                            </thead>

                            <tbody>
                                {scheme.map((s, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <td>
                                                <Center>
                                                    {/* <Checkbox
                                                    _selected={s.id === formik.values.plan_selected}
                                                    size='lg'
                                                    value={s.id}
                                                    colorScheme='whatsapp'
                                                    defaultChecked={s.id === formik.values.plan_selected}
                                                    onChange={(e) => {
                                                        console.log(e.target.value);
                                                        formik.setFieldValue('plan_selected', e.target.value);
                                                    }} /> */}

                                                    <Checkbox
                                                        size='lg'
                                                        value={s.id}
                                                        colorScheme='whatsapp'
                                                        isChecked={s.id === formik.values.plan_selected}
                                                        onChange={() => {
                                                            if (formik.values.plan_selected === s.id) {
                                                                formik.setFieldValue('plan_selected', '');
                                                            } else {
                                                                formik.setFieldValue('plan_selected', s.id);
                                                            }
                                                        }}
                                                    />
                                                </Center>
                                            </td>

                                            <td py='4' fontSize='sm'> {s.name}</td>
                                            <td py='4' fontSize='sm'> {s.monthly_price}</td>
                                            <td py='4' fontSize='sm'> {s.months}</td>
                                            <td py='4' fontSize='sm'> {s.total_investment}</td>
                                            <td py='4' fontSize='sm'> {s.total_return}</td>
                                            <td py='4' fontSize='sm'> {s.return_percent}</td>

                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>

                        {/* <FormControl>
                        <HStack w={'100%'}>
                            <FormLabel htmlFor="plan_selected" w={'30%'}>Plan Selected:</FormLabel>
                            <Box w='100%'>
                            <Select
                                    variant='filled'
                                    options={schemesDropdown}
                                    onChange={(selectedOption) =>
                                        formik.setFieldValue(
                                            'plan_selected',
                                            selectedOption.value,
                                        )
                                    }
                                    placeholder='Select Scheme'
                                />
                            </Box>
                        </HStack>
                    </FormControl> */}

                        {/* {formik.values.plan_selected !== "" ?
                        <VStack w='100%'>
                            <HStack w={'100%'}>
                                <FormLabel htmlFor="plan_selected" w={'30%'}>Monthly Price :</FormLabel>

                                <Text bg='gray.100' w='100%' p='2' rounded='md'>{formik.values.plan_selected.monthly_price}</Text>
                            </HStack>

                            <HStack w={'100%'}>
                                <FormLabel htmlFor="plan_selected" w={'30%'}>Installments :</FormLabel>

                                <Text bg='gray.100' w='100%' p='2' rounded='md'>{formik.values.plan_selected.months}</Text>
                                </HStack>
                        </VStack>
                        : <></>} */}

                        {/* <FormControl>
                        <HStack w={'100%'}>
                            <FormLabel htmlFor="authourised_store" w={'30%'}>AUTHOURISED STORE:</FormLabel>
                            <Input
                                id="authourised_store"
                                name="authourised_store"
                                type="text"
                                variant="filled"
                                required={true}
                                onChange={formik.handleChange}
                                value={formik.values.authourised_store}
                            />
                        </HStack>
                    </FormControl> */}

                        <Button
                            mt={6}
                            type="submit"
                            bg='primary.800'
                            colorScheme="whatsapp"
                            py='6'
                            width="full"
                        >
                            Add
                        </Button>
                    </VStack>
                </form>
            </Card>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Adding a new Member</ModalHeader>
                    {/* <ModalCloseButton /> */}
                    <ModalBody>
                        <CircularProgress isIndeterminate color='green' />
                    </ModalBody>
                </ModalContent>
            </Modal>

        </>
    )
}




import {
    Container,
    VStack,
    Card,
    Button,
    FormControl,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Input,
    HStack,
    Text,
} from '@chakra-ui/react'
import { ChevronDownIcon } from "@chakra-ui/icons";
import React, { useState } from 'react'
import { useAuth } from '../../providers/AuthProvider';
import { useUsers } from '../../providers/UsersProvider';
import { Link } from 'react-router-dom';
import moment from 'moment';

export default function Members() {

    var { members } = useUsers();
    const { user } = useAuth();

    const [allMembers, setAllMembers] = useState(members);

    const search = (e) => {
        console.log(e.target.value);
        let searchValue = e.target.value;
        if (searchValue === "") {
            setAllMembers(members);
            return;
        } else {
            let filteredMembers = members.filter(m => m.phone.toLowerCase().includes(searchValue.toLowerCase()));
            setAllMembers(filteredMembers);
        }
    }

    return (
        <Container p='4' maxW='6xl'>
            <VStack width='100%' spacing={4}>

                <Card borderRadius='md' p='4' width={'100%'}>
                    <VStack w='100%' spacing='4'>

                        <HStack justifyContent='space-between' w='100%'>

                            <Text fontWeight='bold' className='customfont' fontSize='2xl'>Members</Text>

                            {user.type.toLowerCase() === "location" ?
                                <Link to={'/users/add'}> <Button bg='primary.800' colorScheme='primary'>New Membership</Button> </Link>
                                : <></>}
                        </HStack>

                        <FormControl>
                            <Input
                                name="search"
                                id='search'
                                placeholder='Search for a Member with Phone'
                                onChange={search}
                            />
                        </FormControl>
                    </VStack>
                </Card>

                <Card width={'100%'}>

                    <table>
                        <thead>
                            <tr>
                                <th py='4' fontSize='sm'>Id</th>
                                <th py='4' fontSize='sm'>Plan</th>
                                <th py='4' fontSize='sm'>Name</th>
                                <th py='4' fontSize='sm'>Number</th>
                                <th py='4' fontSize='sm'>Email</th>
                                <th py='4' fontSize='sm'>Monthly Installment</th>
                                <th py='4' fontSize='sm'>Action</th>
                            </tr>
                        </thead>

                        <tbody>
                            {allMembers.map((u, idx) => {
                                return (
                                    <tr key={idx}>
                                        <td py='4' fontSize='sm'> {u.id_number}</td>
                                        <td py='4' fontSize='sm'> {u.scheme_name}</td>
                                        <td py='4' fontSize='sm'> {u.name}</td>
                                        <td py='4' fontSize='sm'> {u.phone}</td>
                                        <td py='4' fontSize='sm'> {u.e_mail}</td>
                                        <td py='4' fontSize='sm'> {u.monthly_price}</td>
                                        <td py='4' fontSize='sm'>
                                            <Menu>
                                                <MenuButton as={Button} colorScheme='purple' rightIcon={<ChevronDownIcon />}>
                                                    Actions
                                                </MenuButton>
                                                <MenuList>
                                                    <MenuItem _hover={{ bg: 'transparent' }}>
                                                        <Link to='/users/print'
                                                            state={
                                                                {
                                                                    "location": u.branch,
                                                                    "name": u.name,
                                                                    "id_number": u.id_number,
                                                                    "user_id_proof": u.user_id_proof,
                                                                    "user_id_no": u.user_id_no,
                                                                    "enrolled_by": u.enrolled_by,
                                                                    "enrollment_date": moment(u.enrollment_date).format("DD-MM-YYYY"),
                                                                    "expiry_date": moment(u.expiry_date).format("DD-MM-YYYY"),
                                                                    "date_of_birth": moment(u.date_of_birth, "YYYY-MM-DD").format("DD-MM-YYYY"),
                                                                    "gender": u.gender,
                                                                    "phone": u.phone,
                                                                    "address": u.address,
                                                                    "city": u.city,
                                                                    "pincode": u.pincode,
                                                                    "e_mail": u.e_mail,
                                                                    "scheme_name": u.scheme_name,
                                                                    "monthly_price": u.monthly_price,
                                                                    "installments": u.installments,
                                                                    "payment_date": u.payment_date,
                                                                    "branch": u.branch,
                                                                    "nominee_name": u.nominee_name,
                                                                    "nominee_relationship": u.nominee_relationship,
                                                                    "nominee_gender": u.nominee_gender,
                                                                    "nominee_phone": u.nominee_phone,
                                                                    "nominee_address": u.nominee_address,
                                                                    "nominee_email": u.nominee_email,
                                                                    "nominee_id_proof": u.nominee_id_proof,
                                                                    "nominee_id_no": u.nominee_id_no,
                                                                }
                                                            }
                                                        >
                                                            <Button w='98%'>Print Membership Form</Button>
                                                        </Link>
                                                    </MenuItem>
                                                    {/* <MenuItem _hover={{ bg: 'transparent' }}>
                                                        <Button w='98%'>
                                                            <Link to='/users/memberships/installments/report' state={u}>
                                                                Print Installment Report
                                                            </Link>
                                                        </Button>
                                                    </MenuItem> */}
                                                    <MenuItem _hover={{ bg: 'transparent' }}>
                                                        <Button w='98%'>
                                                            <Link to='/users/memberships/installments' state={u}>
                                                                View Installments
                                                            </Link>
                                                        </Button>
                                                    </MenuItem>
                                                    {/* <MenuItem>
                                                        <Button w='98%'>
                                                            <Link to='/users/memberships/installments' state={u}>
                                                                Edit
                                                            </Link>
                                                        </Button>
                                                    </MenuItem> */}
                                                </MenuList>
                                            </Menu>
                                            {/* <HStack>
                                                <Link to='/users/print'
                                                    state={
                                                        {
                                                            "location": u.branch,
                                                            "name": u.name,
                                                            "id_number": u.id_number,
                                                            "user_id_proof": u.user_id_proof,
                                                            "user_id_no": u.user_id_no,
                                                            "enrolled_by": u.enrolled_by,
                                                            "enrollment_date": moment(u.enrollment_date).format("DD-MM-YYYY"),
                                                            "expiry_date": moment(u.expiry_date).format("DD-MM-YYYY"),
                                                            "date_of_birth": moment(u.date_of_birth).format("DD-MM-YYYY"),
                                                            "gender": u.gender,
                                                            "phone": u.phone,
                                                            "address": u.address,
                                                            "city": u.city,
                                                            "pincode": u.pincode,
                                                            "e_mail": u.e_mail,
                                                            "scheme_name": u.scheme_name,
                                                            "monthly_price": u.monthly_price,
                                                            "installments": u.installments,
                                                            "payment_date": u.payment_date,
                                                            "branch": u.branch,
                                                            "nominee_name": u.nominee_name,
                                                            "nominee_relationship": u.nominee_relationship,
                                                            "nominee_gender": u.nominee_gender,
                                                            "nominee_phone": u.nominee_phone,
                                                            "nominee_address": u.nominee_address,
                                                            "nominee_email": u.nominee_email,
                                                            "nominee_id_proof": u.nominee_id_proof,
                                                            "nominee_id_no": u.nominee_id_no,
                                                        }
                                                    }
                                                >
                                                    <Button colorScheme='purple'>Print</Button>
                                                </Link>
                                                <Link>
                                                    <Button colorScheme='facebook'>Installments</Button>
                                                </Link>
                                            </HStack> */}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </Card>
            </VStack>
        </Container>
    )
}

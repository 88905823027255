import {
    Container,
    VStack,
    Card,
    Button,
    FormControl,
    FormLabel,
    Input,
    HStack,
    Text,
} from '@chakra-ui/react'
import React from 'react'
import { useScheme } from '../../providers/SchemeProvider';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

export default function EditScheme() {

    const { state } = useLocation();

    var { updateData } = useScheme();

    const formik = useFormik({
        initialValues: {
            name: state.name,
            monthly_price: state.monthly_price,
            months: state.months,
            total_investment: state.total_investment,
            total_return: state.total_return,
            return_percent: state.return_percent
        },
        onSubmit: async (values) => {
            try {
                // if(values.months >= 13 || values.months == 0 ){
                //     toast.error("Please Enter valid Month!");
                //     return;
                // }
                if (values.months <= 0 || values.months == 0) {
                    toast.error("Please Enter valid Month!");
                    return;
                }
                await updateData(state.id, values.name, values.monthly_price, values.months, values.total_investment, values.total_return, values.return_percent);
            } catch (error) {
                toast.error("Error Updating !");
            }
        }
    });


    return (
        <Container p='4' maxW='3xl'>
            <VStack width='100%' spacing={4}>

                <Card borderRadius='md' p='4' width={'100%'}>
                    <HStack justifyContent='space-between'>
                        <Text fontWeight='bold' className='customfont' fontSize='2xl'>Update Scheme</Text>
                    </HStack>
                </Card>

                <Card width={'100%'} p='6'>

                    <form onSubmit={formik.handleSubmit}>
                        <VStack spacing={4} align="flex-start">
                            <FormControl>
                                <FormLabel htmlFor="name">Name</FormLabel>
                                <Input
                                    id="name"
                                    name="name"
                                    type="text"
                                    variant="filled"
                                    required={true}
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel htmlFor="monthly_price">Monthly Price</FormLabel>
                                <Input
                                    id="monthly_price"
                                    name="monthly_price"
                                    type="number"
                                    variant="filled"
                                    required={true}
                                    onChange={formik.handleChange}
                                    value={formik.values.monthly_price}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel htmlFor="months">Months</FormLabel>
                                <Input
                                    id="months"
                                    name="months"
                                    type="number"
                                    variant="filled"
                                    required={true}
                                    onChange={formik.handleChange}
                                    value={formik.values.months}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel htmlFor="total_investment">Total Investment</FormLabel>
                                <Input
                                    id="total_investment"
                                    name="total_investment"
                                    type="number"
                                    variant="filled"
                                    required={true}
                                    onChange={formik.handleChange}
                                    value={formik.values.total_investment}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel htmlFor="total_return">Total Return</FormLabel>
                                <Input
                                    id="total_return"
                                    name="total_return"
                                    type="number"
                                    variant="filled"
                                    required={true}
                                    onChange={formik.handleChange}
                                    value={formik.values.total_return}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel htmlFor="return_percent">Return Percent</FormLabel>
                                <Input
                                    id="return_percent"
                                    name="return_percent"
                                    type="number"
                                    variant="filled"
                                    required={true}
                                    onChange={formik.handleChange}
                                    value={formik.values.return_percent}
                                />
                            </FormControl>

                            <Button type="submit" bg='primary.800' colorScheme="primary" py='6' width="full">
                                Update
                            </Button>

                        </VStack>
                    </form>
                </Card>
            </VStack>
        </Container>
    )
}




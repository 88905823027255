import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useAuth } from "./AuthProvider";
import { url } from "../config";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

const LocationContext = createContext();

const LocationProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const { token, logged, logout } = useAuth();

    const [location, setLocation] = useState([]);

    const navigate = useNavigate();

    async function addData(name, username, password, location) {
        console.log(name, username, password)
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            await axios.post(`${url}/admin/locations`,
                {
                    name,
                    username,
                    password,
                    location
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + token
                    },
                },
            );
            await getAll();
            navigate("/locations");
            toast.success("Added !");
        } catch (error) {
            console.error(error);
            if (error.response.status === 400) {
                toast.error("Username or Name Already Exists");
            }
            if (error.response.status === 500) {
                toast.error("Error Adding !");
            }
            throw "Error";
        }
    }

    async function updateData(id, name, username, password) {
        console.log("Update Location");
        console.log(id, name, username);
        setLoading(true);
        // console.log(token);
        if (!logged) {
            return;
        }
        try {
            var { data } = await axios.put(`${url}/admin/locations`,
                {
                    id,
                    name,
                    username,
                    password
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + token
                    },
                },
            );
            console.log(data);
            await getAll();
            navigate("/locations");
            toast.success("Updated !");
        } catch (error) {
            if (error.response.status === 400) {
                toast.error("Username or Name Already Exists");
            }
            return "err";
        }
    }

    async function deleteData(id) {
        console.log(id)
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            await axios.delete(`${url}/admin/locations`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                data: {
                    "id": id,
                },
            }
            );
            await getAll();
            toast.success("DELETED Successfully !");
        } catch (error) {
            console.error(error);
            toast.error("Can't delete active Location With Members.");
            return "err";
        }
    }

    async function getAll() {
        console.log("Get All Config Data at Once");
        setLoading(true);
        // console.log(token);
        if (!logged) {
            return;
        }
        try {
            var { data } = await axios.get(`${url}/admin/locations`,
                {
                    headers: {
                        Authorization: 'Bearer ' + token
                    },
                },
            );
            setLocation(data.data);

            setLoading(false);
        } catch (error) {
            console.error(error);
            if (error.response.status === 401) {
                console.error("NOT AUTHENTICATED");
                logout();
            }
            return "err";
        }
    }


    useEffect(() => {
        getAll();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            loading,
            location,
            addData,
            deleteData,
            updateData,
        }),
        [location, logged, loading] // eslint-disable-line react-hooks/exhaustive-deps
    );

    // Provide the Locationcontext context to the children components
    return (
        <LocationContext.Provider value={contextValue}>{children}</LocationContext.Provider>
    );
};

export const useStoreLocations = () => {
    return useContext(LocationContext);
};

export default LocationProvider;
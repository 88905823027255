import React from 'react';
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../../providers/AuthProvider';
import UsersProvider from '../../providers/UsersProvider';
import Navbar from '../common/Navbar';
import LocationProvider from '../../providers/LocationProvider';
import SchemeProvider from '../../providers/SchemeProvider';
import MemberShipProvider from '../../providers/MemberShipProvider';
export default function PrivateRoutes() {
    const { loading, logged } = useAuth();

    if (loading) {
        return (
            <React.Fragment>
                <p>Loading...</p>
            </React.Fragment>
        )
    }
    return (
        logged ?
            <UsersProvider>
                <LocationProvider >
                    <SchemeProvider>
                        <MemberShipProvider>
                            <Navbar />
                            <Outlet />
                        </MemberShipProvider>
                    </SchemeProvider>
                </LocationProvider>
            </UsersProvider>
            : <Navigate to='/login' />
    )
}


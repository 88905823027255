import {
  Container,
  VStack,
  Card,
  HStack,
  Button,
  Text,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';
import { useStoreLocations } from '../../providers/LocationProvider';
import { ConfirmDialog } from '../utils/ConfirmDialog';

export default function GetLocations() {

  var { location, deleteData } = useStoreLocations();

  const { user } = useAuth();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(-1);

  const handleConfirm = async () => {
    try {
      await deleteData(selectedId);
      setIsOpen(false);
    } catch (error) {

    }
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  // if (loading) {
  //   return (
  //     <>
  //       <Center>
  //         <CircularProgress isIndeterminate color='whsatsapp'></CircularProgress>
  //       </Center>
  //     </>
  //   )
  // }

  return (
    <Container p='4' maxW='4xl'>
      <VStack width='100%' spacing={4}>

        <Card borderRadius='md' p='4' width={'100%'}>
          <HStack justifyContent='space-between'>

            {user.type.toLowerCase() === "admin" ?
              <Text fontWeight='bold' className='customfont' fontSize='2xl'>Locations</Text>
              : user.type.toLowerCase() === "location" ?
                <>
                  <Text fontWeight='bold' className='customfont' fontSize='2xl'>Members</Text>
                </>
                : <></>}

            {user.type.toLowerCase() === "admin" ?
              <Link to={'/locations/add'}>
                <Button bg='primary.800' colorScheme='primary'>Add</Button>
              </Link>
              : user.type.toLowerCase() === "location" ?
                <Link to={'/locations/add'}>
                  <Button bg='primary.800' colorScheme='primary'>Add New Membership</Button>
                </Link>
                : <></>}
          </HStack>
        </Card>

        <Card width={'100%'}>

          <table>
            <thead>
              <tr>
                <th py='4' fontSize='sm'>Id</th>
                <th py='4' fontSize='sm'>Name</th>
                <th py='4' fontSize='sm'>UserName</th>
                <th style={{ width: '160px' }} py='4' fontSize='sm'>Total Users</th>
                {user.type.toLowerCase() === "admin" ?
                  <th py='4' fontSize='sm'>Action</th>
                  : <></>}
              </tr>
            </thead>

            <tbody>
              {location.map((l, idx) => {
                return (
                  <tr key={idx}>
                    <td py='4' fontSize='sm'>{l.id}</td>
                    <td py='4' fontSize='sm'> {l.name}</td>
                    <td py='4' fontSize='sm'> {l.username}</td>
                    <td py='4' fontSize='sm'> {l.users}</td>
                    {user.type.toLowerCase() === "admin" ?
                      <td py='4' fontSize='sm'>
                        <HStack>
                          <Link to={`/locations/edit`} state={l}>
                            <Button size="sm" colorScheme='green'>Update</Button>
                          </Link>

                          <Button size="sm" onClick={() => { setSelectedId(l.id); setIsOpen(true); }} colorScheme='red'>
                            Delete
                          </Button>
                        </HStack>
                      </td>
                      : <></>}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Card>
      </VStack>

      <ConfirmDialog
        isOpen={isOpen}
        onClose={handleClose}
        onConfirm={handleConfirm}
        title="Are you sure?"
        body="This action cannot be undone."
      />
    </Container>
  )
}



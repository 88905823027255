import axios from "axios";
import { createContext, useContext, useMemo, useState } from "react";
import { url } from "../config";
import { useAuth } from "./AuthProvider";
import { toast } from "react-toastify";

const MemberShipContext = createContext();

const MemberShipProvider = ({ children }) => {
    const [installments, setInstallments] = useState([]);
    const [loading, setLoading] = useState(true);

    const { token, logout } = useAuth();

    async function getInstallments(id) {
        setInstallments([]);
        console.log("Installments");
        setLoading(true);
        // if (!logged) {
        //     return;
        // }
        try {
            var { data } = await axios.get(`${url}/memberships/${id}/installments`,
                {
                    headers: {
                        Authorization: 'Bearer ' + token
                    },
                },
            );
            console.log(data.data[0].payments);
            setInstallments(data.data[0].payments);
            setLoading(false);
        } catch (error) {
            console.error("GET Installments ERROR");
            console.error(error);
            if (error.response.status === 401) {
                console.error("NOT AUTHENTICATED");
                logout();
            }
            return "err";
        }
    }

    async function acceptStorePayment(values) {
        try {
            var { data } = await axios.post(`${url}/memberships/installments/accept-payment-by-store`,
                values,
                {
                    headers: {
                        Authorization: 'Bearer ' + token
                    },
                },
            );
            console.log(data);
            getInstallments(values.id);
            setLoading(false);
            toast.success("Accepted Payment !");
        } catch (error) {
            console.error(error);
            if (error.response.status === 401) {
                console.error("NOT AUTHENTICATED");
                logout();
            }
            return "err";
        }
    }

    const contextValue = useMemo(
        () => ({
            installments,
            getInstallments,
            acceptStorePayment,
        }),
        [loading, installments] // eslint-disable-line react-hooks/exhaustive-deps
    );

    return (
        <MemberShipContext.Provider value={contextValue}>{children}</MemberShipContext.Provider>
    );
};

export const useMemberShip = () => {
    return useContext(MemberShipContext);
};

export default MemberShipProvider;
import {
    Container,
    VStack,
    Card,
    Button,
    HStack,
    Text,
} from '@chakra-ui/react'
import React from 'react'
import { useUsers } from '../../providers/UsersProvider';
import { Link } from 'react-router-dom';

export default function AdminMembers() {

    var { adminMembers } = useUsers();

    return (
        <Container p='4' maxW='8xl'>
            <VStack width='100%' spacing={4}>

                <Card borderRadius='md' p='4' width={'100%'}>

                    <HStack justifyContent='space-between' w='100%'>

                        <Text fontWeight='bold' className='customfont' fontSize='2xl'>Members</Text>

                    </HStack>
                </Card>

                <Card width={'100%'}>

                    <table>
                        <thead>
                            <tr>
                                <th py='4' fontSize='sm'>Id</th>
                                <th py='4' fontSize='sm'>Plan</th>
                                <th py='4' fontSize='sm'>Name</th>
                                <th py='4' fontSize='sm'>Number</th>
                                <th py='4' fontSize='sm'>Email</th>
                                <th py='4' fontSize='sm'>Aadhar/Pan</th>
                                <th py='4' fontSize='sm'>Store</th>
                                <th py='4' fontSize='sm'>Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            {adminMembers.map((u, idx) => {
                                return (
                                    <tr key={idx}>
                                        <td py='4' fontSize='sm'>{u.members.id_number}</td>
                                        <td py='4' fontSize='sm'> {u.members.scheme_name}</td>
                                        <td py='4' fontSize='sm'> {u.members.name}</td>
                                        <td py='4' fontSize='sm'> {u.members.phone}</td>
                                        <td py='4' fontSize='sm'> {u.members.e_mail}</td>
                                        <td py='4' fontSize='sm'> {u.members.user_id_no}</td>
                                        <td py='4' fontSize='sm'> {u.locations.name}</td>
                                        <td py='4' fontSize='sm'>
                                            <HStack>
                                                <Link to='/users/edit' state={u} >
                                                    <Button colorScheme='whatsapp'>Edit</Button>
                                                </Link>
                                            </HStack>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </Card>
            </VStack>
        </Container>
    )
}

import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { url } from "../config";
import { toast } from "react-toastify";
import { useAuth } from "./AuthProvider";
// import useAuth from './AuthProvider';

const UsersContext = createContext();

const UsersProvider = ({ children }) => {
    const [users, setUsers] = useState([]);
    const [members, setMembers] = useState([]);
    const [adminMembers, setAdminMembers] = useState([]);
    const [loading, setLoading] = useState(true);

    const { token, user, logged, logout } = useAuth();

    async function addUser(
        data,
    ) {
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            var res = await axios.post(`${url}/users`,
                data,
                {
                    headers: {
                        Authorization: 'Bearer ' + token
                    },
                },
            );
            await getUsers();
            await getMembers();
            toast.success("Added !");
            return res.data;
        } catch (error) {
            console.error(error);
            if (error.response.status === 400) {
                toast.error(error.response.data.error);
            }
            throw "Error";
        }
    }

    async function getUsers() {
        console.log("USERS");
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            var { data } = await axios.get(`${url}/users`,
                {
                    headers: {
                        Authorization: 'Bearer ' + token
                    },
                },
            );
            console.log(data.data);
            setUsers(data.data);

        } catch (error) {
            console.error("GET USER ERROR");
            console.error(error);
            if (error.response.status === 401) {
                console.error("NOT AUTHENTICATED");
                logout();
            }
            return "err";
        }
    }

    async function getMembers() {
        console.log("Members");
        console.log(user);
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            var { data } = await axios.post(`${url}/users/location`,
                {
                    "location": user.id
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                },
            );
            console.log(data.data);
            setMembers(data.data);

        } catch (error) {
            console.error("GET USER ERROR");
            console.error(error);
            if (error.response.status === 401) {
                console.error("NOT AUTHENTICATED");
                logout();
            }
            return "err";
        }
    }

    async function getAdminMembers() {
        console.log("Admin MEMBERS");
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            var { data } = await axios.get(`${url}/admin/memberships`,
                {
                    headers: {
                        Authorization: 'Bearer ' + token
                    },
                },
            );
            console.log(data.data);
            setAdminMembers(data.data);

            console.log(data.data);

        } catch (error) {
            console.error("GET MEMBER ERROR");
            console.error(error);
            if (error.response.status === 401) {
                console.error("NOT AUTHENTICATED");
                logout();
            }
            return "err";
        }
    }

    async function deleteData(id) {
        console.log(id)
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            await axios.delete(`${url}/admin/memberships/delete`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    data: {
                        "id": id,
                    },
                }
            );
            await getAdminMembers();
            toast.success("DELETED Successfully !");
        } catch (error) {
            console.error(error);
            toast.error("Can't delete active Location With Members.");
            return "err";
        }
    }

    async function getUserWithSamePhone(phone) {
        console.log(phone)
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            var { data } = await axios.post(`${url}/users/location/same-phone`,
                {
                    "location": user.id,
                    "phone": phone,
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                }
            );

            return data.length;

            toast.success("DELETED Successfully !");
        } catch (error) {
            // console.error(error);
            // toast.error("Can't delete active Location With Members.");
            return [];
        }
    }

    useEffect(() => {
        getUsers();
        getMembers();
        getAdminMembers();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            users,
            loading,
            addUser,
            members,
            adminMembers,
            getUsers,
            getUserWithSamePhone,
            deleteData,
        }),
        [loading, users, members, adminMembers] // eslint-disable-line react-hooks/exhaustive-deps
    );

    // Provide the authentication context to the children components
    return (
        <UsersContext.Provider value={contextValue}>{children}</UsersContext.Provider>
    );
};

export const useUsers = () => {
    return useContext(UsersContext);
};

export default UsersProvider;

import { useLocation } from 'react-router-dom'
import {
    Container,
    VStack,
    Card,
    Button,
    HStack,
    Text,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import moment from 'moment';
import { ConfirmDialog } from '../utils/ConfirmDialog';
import { useUsers } from '../../providers/UsersProvider';
import { useNavigate } from 'react-router-dom';

export default function AdminLocationMembers() {
    const { state } = useLocation();

    const { deleteData } = useUsers();
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(-1);

    const handleConfirm = async () => {
        try {
            await deleteData(selectedId);
            setIsOpen(false);
            navigate(-1);
        } catch (error) {

        }
    };
    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <>
            <Container p='4' maxW='6xl'>
                <VStack width='100%' spacing={4}>

                    <Card borderRadius='md' p='4' width={'100%'}>
                        <HStack justifyContent='space-between'>

                            <Text fontWeight='bold' className='customfont' fontSize='2xl'>Members</Text>

                        </HStack>
                    </Card>

                    <Card width={'100%'}>

                        <table>
                            <thead>
                                <tr>
                                    <th py='4' fontSize='sm'>Name</th>
                                    <th py='4' fontSize='sm'>ID Number</th>
                                    <th py='4' fontSize='sm'>Scheme</th>
                                    <th py='4' fontSize='sm'>Monthly</th>
                                    <th py='4' fontSize='sm'>Phone</th>
                                    <th py='4' fontSize='sm'>Email</th>
                                    <th py='4' fontSize='sm'>DOB</th>
                                    <th py='4' fontSize='sm'>Enrollment Date</th>
                                    <th py='4' fontSize='sm'>Expiry Date</th>
                                    <th py='4' fontSize='sm'>Action</th>

                                </tr>
                            </thead>

                            <tbody>
                                {state.map((u, idx) => {
                                    return (
                                        <tr key={idx}>
                                            {/* <td py='4' fontSize='sm'>{u}</td> */}
                                            <td py='4' fontSize='sm'> {u.name}</td>
                                            <td py='4' fontSize='sm'> {u.id_number}</td>
                                            <td py='4' fontSize='sm'> {u.scheme_name}</td>
                                            <td py='4' fontSize='sm'> {u.monthly_price}</td>
                                            <td py='4' fontSize='sm'> {u.phone}</td>
                                            <td py='4' fontSize='sm'> {u.e_mail}</td>
                                            <td py='4' fontSize='sm'> {moment(u.date_of_birth).format("DD-MM-YYYY")}</td>
                                            <td py='4' fontSize='sm'> {moment(u.enrollment_date).format("DD-MM-YYYY")}</td>
                                            <td py='4' fontSize='sm'> {moment(u.expiry_date).format("DD-MM-YYYY")}</td>
                                            <td py='4' fontSize='sm'>

                                                <Button onClick={() => { setSelectedId(u.id); setIsOpen(true); }} colorScheme='red'>Delete</Button>

                                            </td>

                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </Card>
                </VStack>
            </Container>

            <ConfirmDialog
                isOpen={isOpen}
                onClose={handleClose}
                onConfirm={handleConfirm}
                title="Are you sure?"
                body="This action cannot be undone."
            />
        </>
    )
}

import {
    VStack,
    Card,
    Button,
    FormControl,
    FormLabel,
    Input,
    HStack,
    Text,
    Box,
    Checkbox,
    Radio,
    RadioGroup,
    Textarea,
    Center,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    Container,
    ModalBody,
    CircularProgress,
    ModalCloseButton,
    useDisclosure,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useUsers } from '../../providers/UsersProvider';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useScheme } from '../../providers/SchemeProvider';
import { useAuth } from '../../providers/AuthProvider';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

export default function EditUser() {

    var { addUser, getUserWithSamePhone } = useUsers();
    const { user } = useAuth();
    const { scheme } = useScheme();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const navigate = useNavigate();
    const { state } = useLocation();

    console.log(state);

    const formik = useFormik({
        initialValues: {
            // id_number: state.members.id_number,
            mobile_no: state.members.phone,
            enrolmnt_date: "",
            expiry_date: "",
            name: state.members.name,
            dob: "",
            gender: state.members.gender,
            email: state.members.e_mail,
            id_proof: state.members.user_id_proof,
            nominee_name: state.members.nominee_name,
            nominee_relationship: state.members.nominee_relationship,
            nominee_phone: state.members.nominee_phone,
            nominee_id_proof: state.members.nominee_id_proof,
            nominee_id_no: state.members.nominee_id_no,
            address: state.members.address,
            city: state.members.city,
            pincode: state.members.pincode,
            id_no: state.members.user_id_no,
            enrolled_by: state.members.enrolled_by,
            authourised_store: "",
            auth_id_no: "",
            plan_selected: "",
        },
        onSubmit: async (values) => {

            let payment_date = moment(values.enrolmnt_date).get('D');
            // console.log(Number.parseInt(payment_date));

            //phone validation
            const mobileNo = values.mobile_no.toString();
            const nomineeMobileNo = values.nominee_phone.toString();
            if (mobileNo.length !== 10 || nomineeMobileNo.length !== 10) {
                toast.error("Phone No should be 10 digit");
                return;
            }

            //user id proof validation
            if (values.id_proof === 'AADHAR' || values.id_proof === 'PAN CARD') {
                if (values.id_proof === 'AADHAR') {
                    if (values.id_no.length != 12) {
                        toast.error("Adhaar No should be 12 digit");
                        return;
                    }
                }
                else if (values.id_proof === 'PAN CARD') {
                    if (values.id_no.length != 10) {
                        toast.error("PAN No should be 10 digit");
                        return;
                    }
                }
            } else {
                toast.error("Please select user id proof!");
                return;
            }

            //nominee id proof validation
            if (values.nominee_id_proof === 'AADHAR' || values.nominee_id_proof === 'PAN CARD') {
                if (values.nominee_id_proof === 'AADHAR') {
                    if (values.nominee_id_no.length != 12) {
                        toast.error("Adhaar No should be 12 digit");
                        return;
                    }
                }
                else if (values.nominee_id_proof === 'PAN CARD') {
                    if (values.nominee_id_no.length != 10) {
                        toast.error("PAN No should be 10 digit");
                        return;
                    }
                }
            } else {
                toast.error("Please select nominee id proof!");
                return;
            }

            if (Number.parseInt(payment_date) > 28) {
                payment_date = "28";
                toast.info("Payment Date is set to 28 to avoid conflicts !");
                // return;
            }

            if (values.plan_selected === "") {
                toast.error("Please select a plan!");
                return;
            }

            var selectedPlan;
            for (var p of scheme) {
                // console.log(p);
                if (p.id == values.plan_selected) {
                    selectedPlan = p;
                    break;
                }
            }

            return;

            try {
                onOpen();
                console.log(user);
                let dataToAdd = {
                    "location": user.id,
                    "name": values.name,
                    "id_number": "",
                    "user_id_proof": values.id_proof,
                    "user_id_no": values.id_no,
                    "enrolled_by": values.enrolled_by,
                    "enrollment_date": values.enrolmnt_date,
                    "expiry_date": values.expiry_date,
                    "date_of_birth": values.dob,
                    "gender": values.gender,
                    "phone": values.mobile_no,
                    "address": values.address,
                    // "state": '',
                    "city": values.city,
                    "pincode": values.pincode,
                    "e_mail": values.email,
                    "scheme_name": selectedPlan.name,
                    "monthly_price": selectedPlan.monthly_price,
                    "installments": selectedPlan.months,
                    "payment_date": payment_date,
                    "branch": user.name,
                    "nominee_name": values.nominee_name,
                    "nominee_relationship": values.nominee_relationship,
                    "nominee_gender": values.nominee_gender,
                    "nominee_phone": values.nominee_phone,
                    "nominee_address": values.nominee_address,
                    "nominee_email": values.nominee_email,
                    "nominee_id_proof": values.nominee_id_proof,
                    "nominee_id_no": values.nominee_id_no,
                };

                var res = await addUser(dataToAdd);

                navigate("/users/print", { state: res.data });
                console.log(dataToAdd);
            } catch (error) {
                console.log(error);
                toast.error("Error Adding !");
            } finally {
                onClose();
            }
        }
    });

    return (
        <>
            <Container p='4' maxW='3xl'>
                <VStack width='100%' spacing={4}>

                    <Card borderRadius='md' p='4' width={'100%'}>
                        <HStack justifyContent='space-between'>
                            <Text fontWeight='bold' className='customfont' fontSize='2xl'>Edit  Member</Text>
                        </HStack>
                    </Card>

                    <Card borderRadius='md' p='4' width={'100%'}>
                        <VStack justifyContent='space-between' alignItems='start'>
                            <Text fontSize='md'>Membership Id : {state.members.id_number} </Text>
                            <Text fontSize='md'>Scheme : {state.members.scheme_name}</Text>
                            <Text fontSize='md'>Enrollment Date : {moment(state.members.enrollment_date).format("DD-MM-YYYY")} </Text>
                            <Text fontSize='md'>Expiry Date : {moment(state.members.expiry_date).format("DD-MM-YYYY")} </Text>
                        </VStack>
                    </Card>

                    <Card width={'100%'} p='6'>
                        <form onSubmit={formik.handleSubmit}>
                            <VStack spacing={4} align="flex-start">

                                <Card borderRadius='md' width={'100%'} bg='primary.800' colorScheme='primary'>
                                    <Text fontWeight='500' className='customfont' p={3} fontSize='lg' color={'white'}>Personal Information</Text>
                                </Card>

                                <FormControl>
                                    <HStack w={'100%'}>
                                        <FormLabel htmlFor="mobile_no" w={'30%'}>Mobile Number:</FormLabel>
                                        <Input
                                            id="mobile_no"
                                            name="mobile_no"
                                            type="number"
                                            variant="filled"
                                            maxLength="10"
                                            required={true}
                                            // onChange={formik.handleChange}
                                            onChange={async (e) => {
                                                let value = e.target.value;
                                                // console.log(value);
                                                formik.setFieldValue('mobile_no', e.target.value);
                                                if (value.length == 10) {
                                                    // console.log("GET DATA");
                                                    var usersWithPhone = await getUserWithSamePhone(value);
                                                    // console.log(usersWithPhone);
                                                    if (usersWithPhone > 0) {
                                                        toast.info("A Member already Exists with this phone Number",
                                                            { position: 'top-center', closeButton: true, }
                                                        );
                                                    }
                                                }

                                            }}
                                            value={formik.values.mobile_no}
                                        />
                                    </HStack>
                                </FormControl>

                                <FormControl>
                                    <HStack w={'100%'}>
                                        <FormLabel htmlFor="name" w={'30%'}>Name:</FormLabel>
                                        <Input
                                            id="name"
                                            name="name"
                                            type="text"
                                            variant="filled"
                                            required={true}
                                            onChange={formik.handleChange}
                                            value={formik.values.name}
                                        />
                                    </HStack>
                                </FormControl>

                                {/* <FormControl>
                                    <HStack w={'100%'}>
                                        <FormLabel htmlFor="dob" w={'30%'}>
                                            Date of Birth:
                                        </FormLabel>
                                        <Input
                                            id="dob"
                                            name="dob"
                                            type="date"
                                            variant="filled"
                                            required={true}
                                            onChange={formik.handleChange}
                                            value={formik.values.dob}
                                        />
                                    </HStack>
                                </FormControl> */}

                                <FormControl>
                                    <HStack w={'100%'}>
                                        <FormLabel htmlFor="gender" w={'150px'}>
                                            Gender:
                                        </FormLabel>
                                        <RadioGroup
                                            name="gender"
                                            value={formik.values.gender}
                                            onChange={formik.handleChange}
                                            w={'70%'}
                                        >
                                            <Radio
                                                value="Male"
                                                size='lg'
                                                colorScheme='primary'
                                                checked={formik.values.gender === 'Male'}
                                                onChange={formik.handleChange}
                                            >
                                                Male
                                            </Radio>
                                            <Box display='inline-block' p='2' />
                                            <Radio
                                                value="Female"
                                                size='lg'
                                                colorScheme='primary'
                                                checked={formik.values.gender === 'Female'}
                                                onChange={formik.handleChange}
                                            >
                                                Female
                                            </Radio>
                                        </RadioGroup>
                                    </HStack>
                                </FormControl>

                                <FormControl>
                                    <HStack w={'100%'}>
                                        <FormLabel htmlFor="address" w={'30%'}>Address:</FormLabel>
                                        <Textarea
                                            id="address"
                                            name="address"
                                            type="text"
                                            maxLength="100"
                                            variant="filled"
                                            required={true}
                                            onChange={formik.handleChange}
                                            value={formik.values.address}
                                            rows={2}
                                        />
                                    </HStack>
                                </FormControl>

                                <FormControl>
                                    <HStack w={'100%'}>
                                        <FormLabel htmlFor="city" w={'30%'}>City:</FormLabel>
                                        <Input
                                            id="city"
                                            name="city"
                                            type="text"
                                            maxLength="50"
                                            variant="filled"
                                            required={true}
                                            onChange={formik.handleChange}
                                            value={formik.values.city}
                                        />
                                    </HStack>
                                </FormControl>

                                <FormControl>
                                    <HStack w={'100%'}>
                                        <FormLabel htmlFor="pincode" w={'30%'}>Pin Code:</FormLabel>
                                        <Input
                                            id="pincode"
                                            name="pincode"
                                            maxLength="6"
                                            type="number"
                                            variant="filled"
                                            required={true}
                                            onChange={formik.handleChange}
                                            value={formik.values.pincode}
                                        />
                                    </HStack>
                                </FormControl>

                                <FormControl>
                                    <HStack w={'100%'}>
                                        <FormLabel htmlFor="id_proof" w={'150px'}>
                                            ID PROOF:
                                        </FormLabel>
                                        <RadioGroup
                                            name="id_proof"
                                            value={formik.values.id_proof}
                                            onChange={formik.handleChange}
                                            w={'70%'}
                                        >
                                            <Radio
                                                value="AADHAR"
                                                size='lg'
                                                colorScheme='primary'
                                                checked={formik.values.id_proof === 'AADHAR'}
                                                onChange={formik.handleChange}
                                            >
                                                Adhaar Card
                                            </Radio>
                                            <Box display='inline-block' p='2' />
                                            <Radio
                                                value="PAN CARD"
                                                size='lg'
                                                colorScheme='primary'
                                                checked={formik.values.id_proof === 'PAN CARD'}
                                                onChange={formik.handleChange}
                                            >
                                                Pan Card
                                            </Radio>
                                        </RadioGroup>
                                    </HStack>
                                </FormControl>

                                <FormControl>
                                    <HStack w={'100%'}>
                                        <FormLabel htmlFor="id_no" w={'30%'}>ID No:</FormLabel>
                                        <Input
                                            id="id_no"
                                            name="id_no"
                                            type="text"
                                            variant="filled"
                                            required={true}
                                            onChange={formik.handleChange}
                                            value={formik.values.id_no}
                                        />
                                    </HStack>
                                </FormControl>

                                <FormControl>
                                    <HStack w={'100%'}>
                                        <FormLabel htmlFor="enrolmnt_by" w={'30%'}>ENROLLED BY:</FormLabel>
                                        <Input
                                            id="enrolled_by"
                                            name="enrolled_by"
                                            type="text"
                                            variant="filled"
                                            maxLength="50"
                                            required={true}
                                            onChange={formik.handleChange}
                                            value={formik.values.enrolled_by}
                                        />
                                    </HStack>
                                </FormControl>


                                <FormControl>
                                    <HStack w={'100%'}>
                                        <FormLabel htmlFor="email" w={'30%'}>Email ID:</FormLabel>
                                        <Input
                                            id="email"
                                            name="email"
                                            type="email"
                                            maxLength="56"
                                            variant="filled"
                                            required={true}
                                            onChange={formik.handleChange}
                                            value={formik.values.email}
                                        />
                                    </HStack>
                                </FormControl>

                                <FormControl>
                                    <HStack w={'100%'}>
                                        <FormLabel htmlFor="nominee_name" w={'30%'}>Nominee Name:</FormLabel>
                                        <Input
                                            id="nominee_name"
                                            name="nominee_name"
                                            maxLength="100"
                                            type="text"
                                            variant="filled"
                                            required={true}
                                            onChange={formik.handleChange}
                                            value={formik.values.nominee_name}
                                        />
                                    </HStack>
                                </FormControl>

                                <FormControl>
                                    <HStack w={'100%'}>
                                        <FormLabel htmlFor="nominee_relationship" w={'30%'}>
                                            Relationship with Account Holder:
                                        </FormLabel>
                                        <Input
                                            id="nominee_relationship"
                                            name="nominee_relationship"
                                            maxLength="50"
                                            type="text"
                                            variant="filled"
                                            required={true}
                                            onChange={formik.handleChange}
                                            value={formik.values.nominee_relationship}
                                        />
                                    </HStack>
                                </FormControl>

                                <FormControl>
                                    <HStack w={'100%'}>
                                        <FormLabel htmlFor="nominee_phone" w={'30%'}>Mobile No:</FormLabel>
                                        <Input
                                            id="nominee_phone"
                                            name="nominee_phone"
                                            type="number"
                                            maxLength="10"
                                            variant="filled"
                                            required={true}
                                            onChange={formik.handleChange}
                                            value={formik.values.nominee_phone}
                                        />
                                    </HStack>
                                </FormControl>

                                <FormControl>
                                    <HStack w={'100%'}>
                                        <FormLabel htmlFor="nominee_id_proof" w={'150px'}>
                                            ID PROOF:
                                        </FormLabel>
                                        <RadioGroup
                                            name="nominee_id_proof"
                                            value={formik.values.nominee_id_proof}
                                            onChange={formik.handleChange}
                                            w={'70%'}
                                        >
                                            <Radio
                                                value="AADHAR"
                                                size='lg'
                                                colorScheme='primary'
                                                checked={formik.values.nominee_id_proof === 'AADHAR'}
                                                onChange={formik.handleChange}
                                            >
                                                Adhaar Card
                                            </Radio>
                                            <Box display='inline-block' p='2' />
                                            <Radio
                                                value="PAN CARD"
                                                size='lg'
                                                colorScheme='primary'
                                                checked={formik.values.nominee_id_proof === 'PAN CARD'}
                                                onChange={formik.handleChange}
                                            >
                                                Pan Card
                                            </Radio>
                                        </RadioGroup>
                                    </HStack>
                                </FormControl>

                                <FormControl>
                                    <HStack w={'100%'}>
                                        <FormLabel htmlFor="nominee_id_no" w={'30%'}>ID No:</FormLabel>
                                        <Input
                                            id="nominee_id_no"
                                            name="nominee_id_no"
                                            type="text"
                                            variant="filled"
                                            required={true}
                                            onChange={formik.handleChange}
                                            value={formik.values.nominee_id_no}
                                        />
                                    </HStack>
                                </FormControl>


                                <Button
                                    mt={6}
                                    type="submit"
                                    bg='primary.800'
                                    colorScheme="whatsapp"
                                    py='6'
                                    width="full"
                                >
                                    Update
                                </Button>
                            </VStack>
                        </form>
                    </Card>

                    <Modal isOpen={isOpen} onClose={onClose}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Adding a new Member</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <CircularProgress isIndeterminate color='green' />
                            </ModalBody>
                        </ModalContent>
                    </Modal>

                </VStack>
            </Container >

        </>
    )
}




import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import Login from './components/auth/Login';
import Users from './components/master/Users';
import PrivateRoutes from './components/utils/PrivateRoutes';
import AuthProvider from './providers/AuthProvider';
import 'react-toastify/dist/ReactToastify.css';
import GetLocations from './components/master/GetLocations';
import AddLocation from './components/master/AddLocation';
import Schemes from './components/master/Schemes';
import AddScheme from './components/master/AddScheme';
import AddUser from './components/master/AddUser';
import PrintMembership from './components/PrintMembership';
import Members from './components/master/Members';
import EditLocation from './components/master/EditLocation';
import EditScheme from './components/master/EditScheme';
import Dashboard from './components/master/Dashboard';
import PrintMembership2 from './components/PrintMembership2';
import AdminMembers from './components/master/AdminMembers';
import AdminLocationMembers from './components/master/AdminLocationMembers';
import MemberShipInstallments from './components/master/MemberShipInstallments';
import PrintInstallmentReport from './components/PrintInstallmentReport';
import PrintPaymentReceipt from './components/PrintPaymentReceipt';
import EditUser from './components/master/EditUser';

function App() {
  return (
    <AuthProvider key={'authprovider'}>
      <Router>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path='/' element={<Dashboard />} />
            {/* <Route path='/users' element={<Users />} /> */}
            <Route path='/admin/members' element={<AdminMembers />} />
            <Route path='/admin/members/location' element={<AdminLocationMembers />} />
            <Route path='/location/members' element={<Members />} />
            <Route path='/users/add' element={<AddUser />} />
            <Route path='/users/edit' element={<EditUser />} />
            <Route path='/users/print' element={<PrintMembership2 />} />
            <Route path='/users/memberships/installments' element={<MemberShipInstallments />} />
            <Route path='/users/memberships/installments/report' element={<PrintInstallmentReport />} />
            <Route path='/users/memberships/installments/print' element={<PrintPaymentReceipt />} />

            <Route path='/locations' element={<GetLocations />} />
            <Route path='/locations/add' element={<AddLocation />} />
            <Route path='/locations/edit' element={<EditLocation />} />

            <Route path='/schemes' element={<Schemes />} />
            <Route path='/scheme/add' element={<AddScheme />} />
            <Route path='/scheme/edit' element={<EditScheme />} />
          </Route>
          <Route path='/login' element={<Login />} />
        </Routes>
      </Router>
      <ToastContainer />
    </AuthProvider>
  );
}

export default App;
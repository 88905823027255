import { Container, Card, Text, Button, Box, VStack, HStack, Image, Divider } from '@chakra-ui/react'
import moment from 'moment';
import { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

export default function PrintMembership2() {

    const componentRef = useRef();

    const { state } = useLocation();


    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    console.log(state);

    return (
        <>
            <Container maxW='5xl' my='2'>
                <VStack>

                    <Card p='5' w='100%'>
                        <HStack justifyContent='space-between' w='100%'>
                            <Text fontWeight='800'>Membership Details</Text>
                            <Button onClick={handlePrint}>Print</Button>
                        </HStack>
                    </Card>

                    <Box
                        bg='white'
                        ref={componentRef}
                        shadow='md'
                        style={{ height: 'auto', width: '21cm', aspectRatio: '1.41/1', fontSize: 'small' }}
                    >

                        <VStack>

                            <Container
                                bg="white"
                                width='100%' maxW='100%'
                                style={{ height: '29.7cm' }}
                            >

                                <VStack spacing={'3'}>

                                    <Box w='100%' p='4' bg='#641344' alignItems='center' display='flex' justifyContent='center'>
                                        <HStack justifyContent='space-between' w='100%'>
                                            <Image border='0px solid #1c1c1c' alt='Logo' src='/logo-white.png' w='250px' />
                                            <Image border='0px solid #1c1c1c' alt='Logo' src='/logo2.png' w='250px' />
                                        </HStack>
                                    </Box>

                                    <VStack>

                                        <Text fontSize='4xl' fontFamily='customfont'>INVEST & SHINE ENROLLMENT FORM</Text>

                                        {/* <Text fontSize='sm' textAlign='center'>The Invest and Shine Subscription Plan ( hereinafter "Invest & Share" or "Subscription Plan" ) offered by Limelight Lab Grown Diamonds Limited ("Limelight" or the "Company") is introduced to enable Customers to purchase Limelight jewellery and pay for the same over a 10(ten) months period ("Investement Tenure" or "Tenure") and avail certain discounts subject to the Terms and Conditions.</Text> */}

                                    </VStack>

                                    <HStack justifyContent='space-between' w='100%'>

                                        <HStack>
                                            <Text>ID NO : {state.id_number}</Text>
                                        </HStack>

                                        <HStack>
                                            <Text>Enrollment Date: {state.enrollment_date}</Text>
                                        </HStack>

                                    </HStack>

                                    <HStack justifyContent='space-between' w='100%'>
                                        <HStack>
                                            <Text>Mobile Number : {state.phone}</Text>
                                        </HStack>

                                        <HStack>
                                            <Text>Exp Date: {state.expiry_date}</Text>
                                        </HStack>

                                    </HStack>

                                    <Card borderRadius='md' width={'100%'} bg='primary.800' colorScheme='primary'>
                                        <Text fontWeight='500' className='customfont' p={3} fontSize='lg' color={'white'}>Personal Information</Text>
                                    </Card>


                                    <table style={{ width: '20cm', tableLayout: 'fixed', backgroundColor: 'white', fontSize: '9px !important', textAlign: 'start', alignItems: 'start' }}>
                                        <tbody style={{ textAlign: 'start' }}>
                                            <tr>
                                                <td style={{ backgroundColor: 'white', textAlign: 'start' }}>Name : </td>
                                                <td style={{ backgroundColor: 'white', textAlign: 'start' }}>{state.name}</td>
                                                <td style={{ backgroundColor: 'white', textAlign: 'start' }}>Date Of Birth : </td>
                                                <td style={{ backgroundColor: 'white', textAlign: 'start' }}>{state.date_of_birth}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ backgroundColor: 'white', textAlign: 'start' }}>Address : </td>
                                                <td style={{ backgroundColor: 'white', textAlign: 'start' }} colSpan={3}>{state.address}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ backgroundColor: 'white', textAlign: 'start' }}>Email : </td>
                                                <td style={{ backgroundColor: 'white', textAlign: 'start' }} colSpan={3}>{state.e_mail}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ backgroundColor: 'white', textAlign: 'start' }}>Gender : </td>
                                                <td style={{ backgroundColor: 'white', textAlign: 'start' }}>{state.gender}</td>
                                                <td style={{ backgroundColor: 'white', textAlign: 'start' }}>Enrolled By : </td>
                                                <td style={{ backgroundColor: 'white', textAlign: 'start' }}>{state.enrolled_by}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ backgroundColor: 'white', textAlign: 'start' }}>ID Proof : </td>
                                                <td style={{ backgroundColor: 'white', textAlign: 'start' }}>{state.user_id_proof}</td>
                                                <td style={{ backgroundColor: 'white', textAlign: 'start' }}>ID Number : </td>
                                                <td style={{ backgroundColor: 'white', textAlign: 'start' }}>{state.user_id_no}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ backgroundColor: 'white', textAlign: 'start' }}>City : </td>
                                                <td style={{ backgroundColor: 'white', textAlign: 'start' }}>{state.city}</td>
                                                <td style={{ backgroundColor: 'white', textAlign: 'start' }}>Pincode : </td>
                                                <td style={{ backgroundColor: 'white', textAlign: 'start' }}>{state.pincode}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ backgroundColor: 'white', textAlign: 'start' }}>Nominee Name : </td>
                                                <td style={{ backgroundColor: 'white', textAlign: 'start' }}>{state.nominee_name}</td>
                                                <td style={{ backgroundColor: 'white', textAlign: 'start' }}>Relationship With Holder : </td>
                                                <td style={{ backgroundColor: 'white', textAlign: 'start' }}>{state.nominee_relationship}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ backgroundColor: 'white', textAlign: 'start' }}>ID Number : </td>
                                                <td style={{ backgroundColor: 'white', textAlign: 'start' }}>{state.nominee_id_no}</td>
                                                <td style={{ backgroundColor: 'white', textAlign: 'start' }}>ID Proof : </td>
                                                <td style={{ backgroundColor: 'white', textAlign: 'start' }}>{state.nominee_id_proof}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ backgroundColor: 'white', textAlign: 'start', fontSize: '9px !important' }}>Mobile Number : </td>
                                                <td style={{ backgroundColor: 'white', textAlign: 'start', fontSize: '9px !important' }}>{state.nominee_phone}</td>
                                                <td style={{ backgroundColor: 'white', textAlign: 'start', fontSize: '9px !important' }}>Authorized Store : </td>
                                                <td style={{ backgroundColor: 'white', textAlign: 'start', fontSize: '9px !important' }}>{state.branch}</td>
                                            </tr>
                                        </tbody>
                                    </table>



                                    {/* 
                                    <HStack w='100%' alignItems='center' justifyContent='space-between'>

                                        <HStack width='450px'>
                                            <Text w='200px'>Name</Text>
                                            <Text w='100%'>: {state.name}</Text>
                                        </HStack>


                                        <HStack width='450px' alignItems='start'>
                                            <Text w='200px'>Address</Text>
                                            <Text w='100%'>: {state.address}</Text>
                                        </HStack>
                                    </HStack>


                                    <HStack w='100%' alignItems='center'>
                                        <HStack width='450px'>
                                            <Text w='200px'>Date of Birth</Text>
                                            <Text w='100%'>: {state.date_of_birth}</Text>
                                        </HStack>

                                        <HStack width='450px'>

                                        </HStack>
                                    </HStack>


                                    <HStack w='100%'>
                                        <HStack width='450px'>
                                            <Text w='200px'>Gender</Text>
                                            <Text w='100%'>: {state.gender}</Text>
                                        </HStack>

                                        <HStack width='450px'>
                                            <Text w='200px'>City</Text>
                                            <Text w='100%'>: {state.city}</Text>
                                        </HStack>
                                    </HStack>

                                    <HStack w='100%'>
                                        <HStack width='450px' maxW='450px' overflowWrap='anywhere' bg='red'>
                                            <Text w='200px'>E Mail</Text>
                                            <Text w='100%'>: {state.e_mail}</Text>
                                        </HStack>

                                        <HStack width='450px' bg='red'>
                                            <Text w='200px'>Pincode</Text>
                                            <Text w='100%'>: {state.pincode}</Text>
                                        </HStack>
                                    </HStack>


                                    <HStack w='100%'>
                                        <HStack width='450px'>
                                            <Text w='200px'>ID Proof</Text>
                                            <Text w='100%'>: {state.user_id_proof}</Text>
                                        </HStack>

                                        <HStack width='450px'>
                                            <Text w='200px'>ID Number</Text>
                                            <Text w='100%'>: {state.user_id_no}</Text>
                                        </HStack>
                                    </HStack> */}

                                    {/* <HStack w='100%'>
                                        <HStack width='450px'>
                                            <Text w='200px'>Nominee Name</Text>
                                            <Text w='100%'>: {state.nominee_name}</Text>
                                        </HStack>

                                        <HStack width='450px'>
                                            <Text w='200px'>Enrolled By</Text>
                                            <Text w='100%'>: {state.enrolled_by}</Text>
                                        </HStack>
                                    </HStack>

                                    <HStack w='100%'>
                                        <HStack width='450px'>
                                            <Text w='200px'>Relationship With Holder</Text>
                                            <Text w='100%'>: {state.nominee_relationship}</Text>
                                        </HStack>

                                        <HStack width='450px'>
                                            <Text w='200px'>Authorized Store</Text>
                                            <Text w='100%'>: {state.branch}</Text>
                                        </HStack>
                                    </HStack>




                                    <HStack w='100%'>

                                        <HStack width='450px'>
                                            <Text w='200px'>Mobile Number</Text>
                                            <Text w='100%'>: {state.nominee_phone}</Text>
                                        </HStack>

                                        <HStack width='450px'>
                                        </HStack>

                                    </HStack>

                                    <HStack w='100%'>
                                        <HStack width='450px'>
                                            <Text w='200px'>ID Proof</Text>
                                            <Text w='100%'>: {state.nominee_id_proof}</Text>
                                        </HStack>

                                        <HStack width='450px'>
                                            <Text w='200px'>ID Number</Text>
                                            <Text w='100%'>: {state.nominee_id_no}</Text>
                                        </HStack>
                                    </HStack> */}

                                    <Card borderRadius='md' width={'100%'} bg='primary.800' colorScheme='primary'>
                                        <Text fontWeight='500' className='customfont' p={3} fontSize='lg' color={'white'}>
                                            Subscription Plan Selection
                                        </Text>
                                    </Card>

                                    <HStack w='100%'>
                                        <Text w='200px'>Selected Plan</Text>
                                        <Text w='100%'>: {state.scheme_name}</Text>
                                    </HStack>

                                    <HStack w='100%'>
                                        <Text w='200px'>Monthly Installment</Text>
                                        <Text w='100%'>: {state.monthly_price}</Text>
                                    </HStack>

                                    <HStack w='100%'>
                                        <Text w='200px'>Installment</Text>
                                        <Text w='100%'>: {state.installments}</Text>
                                    </HStack>


                                    <HStack w='100%'>
                                        <Text w='200px'>Enrollment Date</Text>
                                        <Text w='100%'>: {state.enrollment_date}</Text>
                                    </HStack>


                                    <HStack w='100%'>
                                        <Text w='200px'>Branch</Text>
                                        <Text w='100%'>: {state.branch}</Text>
                                    </HStack>

                                </VStack>

                            </Container>



                            <Container
                                bg='white'
                                width='100%' maxW='100%'
                                style={{ height: '29.5cm', aspectRatio: '1.41/1' }}
                            >

                                <VStack w='100%'>

                                    <Card borderRadius='md' width={'100%'} bg='primary.800' colorScheme='primary'>
                                        <Text fontWeight='500' className='customfont' p={3} fontSize='lg' color={'white'}>
                                            Terms & Conditions
                                        </Text>
                                    </Card>
                                    <ul style={{ padding: '12px', fontSize: '9.5px' }}>
                                        <li>
                                            Introducing the Invest & Shine Subscription Plan (hereinafter “Invest & Shine” or “Subscription Plan”) offered by Limelight Lab Grown Diamonds Limited (“Limelight” or the “Company”). This plan allows customers to invest in a 10-month scheme and avail additional benefits on the purchase of Limelight jewellery after the completion of the plan, all subject to the Terms and Conditions.
                                        </li>

                                        <li>
                                            Under the Plan, the Customer shall select a specific plan and pay fixed instalments every month as defined in their chosen plan for a period of 10 (ten) months. The monthly instalment amount remains fixed for each plan and no other instalment value will be accepted.
                                        </li>

                                        <li>
                                            To subscribe to Invest & Shine, the Customer will have to open a subscription account by physically visiting any one of the authorised Limelight Stores. Limelight staff shall assist the Customer in opening the Subscription Account.
                                        </li>

                                        <li>
                                            To be eligible to open the Subscription Account, the Customer must be above 18 years of age and a Resident of India.
                                        </li>

                                        <li>The Customer must submit a copy of his/her photo identity and address proof documents (Aadhar Card + PAN Card) and other necessary details as part of enrolment to activate the Subscription Account (“Enrolment Form”).</li>

                                        <li>The Customer must make their first payment immediately on opening of the Subscription Account. The date of the first payment shall be considered as the Custome's Enrolment Date (“Enrolment Date”).</li>

                                        <li>The Subscription plan shall remain in existence for a period of 400 days from the Date of Enrolment (“Subscription End Date”)</li>

                                        <li>During the Tenure, the Customer shall pay one instalment every month as per the chosen Plan. No advance payment for the next months will be accepted.</li>

                                        <li>The due date for payment of the instalment shall be the same date as the enrolment date for the subsequent months (“Due Date”) when the customer is expected to make his/her payment. However, the Customer shall also be provided with a grace period of 7 (seven) days to fulfil the due payment.</li>

                                        <li>If the Customer fails to pay the instalment on the Due Date or within the grace period, the Customer shall be given the option of paying the outstanding payment in the subsequent month along with the next instalment due in that month</li>

                                        <li>The Customer shall remain eligible to redeem the applicable discount as per their chosen Plan only if:
                                            <ul style={{ padding: '0 30px' }}>
                                                <li>
                                                    The Company has received full instalment as per the chosen Plan within the stipulated Tenure from the Enrolment Date
                                                </li>
                                                <li>The Subscription Account of the Customer is active and redemption is after the Tenure and on or before the Subscription End Date</li>
                                                <li>Customer is purchasing any jewellery from any authorised Limelight Stores</li>
                                                <li>Redemption is at the same Store where the Subscription Account was opened</li>

                                            </ul>
                                        </li>

                                        <li>  The Subscription Account is non-transferrable and shall cease to exist post the Subscription End Date. However, in the event of the death of the subscriber, the nominee specified at the time of Enrolment in the Enrolment Form will have the right to pay the balance instalment(s) (if any) and buy products using the Invest & Shine plan of the Customer.</li>

                                        <li> Payment for the monthly instalments can be made using options like credit/debit Cards, Net banking, UPI, Cheque in the favour of “Limelight Lab Grown Diamonds Limited”, etc., or in cash at our authorised Limelight store where the Customer wishes to open the Subscription Account. The Company will not be responsible for any online payment failure and money being debited from Customer's account.</li>

                                        <li>  In case the Customer wishes to discontinue/cancel/terminate the existing plan; the Company shall provide a discount voucher with a validity period of no later than 6 (six) months from the date of issue, and equivalent to the total amount paid by the Customer against the plan. This voucher can be utilised by the Customer at the same Limelight store where the enrolment was done. Note, the Customer cannot purchase coins, chains and solitaires under this plan. They will also not be entitled for a refund of the monthly instalments or any portion thereof towards the plan.</li>

                                        <li>  In case the Customer has paid the entire instalment for the given Tenure but does not wish to or could not redeem after the Tenure and before the Subscription End Date, the said amount shall be fully refunded. A discount voucher for the applicable discount amount with a validity period of no later than 6 (six) months from the date of issue, will be provided which can be utilised only to purchase jewellery from the same Limelight Store of a value equal to or greater than the full value of instalment paid.</li>

                                        <li>  Refund under the Plan, if any, will be made by way of Cheque in the name of the account holder as specified in the Enrolment Form or by online transfer to the bank account as specified in the Enrolment Form. No cash refund is permissible.</li>

                                        <li> The Company or the Stores shall not remain responsible or liable to send reminder for payments.</li>

                                        <li> This plan cannot be clubbed with any other ongoing offers or sale discount or any other gift card.</li>

                                        <li> The Company reserves the right to cancel and refund the money towards the Plan in case of any discrepancy in the details submitted by the Customer or non-submission of a valid ID proof within 30 days of enrolment or breach in the terms & conditions by the Customer. In such cases, the amount paid by the Customer towards subscription shall be refunded. The refund need not be in the same payment mode used by the Customer at the time of making the payment. It will be as per the Company policy.</li>

                                        <li> The Customer may appoint a nominee at the time of enrolment upon submission of relevant documentation. In the event of death of the Subscription Account holder, the amount is transferable by the Company only to the person(s) whose nomination has been filled by the account holder in the Enrolment Form at the time of opening the account, subject to such nominee producing identity and address proof. However, the decision of the Company shall be final on sufficiency of any document in all such cases above and the same shall be binding upon the claimants.</li>


                                        <li> This Plan is only eligible for the enrolment of individuals and is not permissible for other entities like companies, partnership firms/proprietorship concerns, Trusts or Hindu Undivided Family (HUF) or NRI Customers.
                                            In case of any change in contact or address details or any other details that the Customer may have furnished, the Customer shall immediately contact the authorised Limelight Store where the Subscription Account was created for effecting the changes. The Customer shall ensure that the name and address is as per the identity proof provided by the Customer. The Customer must also ensure that the bank account details and other details provided in the Enrolment Form are accurate.</li>

                                        <li>The Company reserves the right to alter, amend, add or delete part or whole of the privileges offered in the Plan without prior notice to the account holder, as long as the same is not detrimental to the interests of the account holder.</li>

                                        <li> The Company is the operator of this Plan and reserves the right to suspend the Plan at any time. In any such event, the account holder may purchase any item at the authorised Limelight Store; equivalent to the instalment value accumulated in his/her Invest & Shine account along with discounts accumulated, as on that day.</li>

                                        <li> The liability of the Company or its franchisee(s) or agents under the Plan is limited to the extent of instalments/advances paid by the account holders and the discount, as per the Plan and the terms and conditions contained herein, and thus does not lead to any other assurance or warrant whatsoever by the Company.</li>

                                        <li> Any conditions that are not explicitly covered above would be the discretion of the Company at the time of transaction/redemption. The decision of the Company in this regard would be deemed as irrevocable and final.</li>

                                        <li> All standard T&C and policies, like return & refund, will remain the same as mentioned by Limelight Lab Grown Diamonds Ltd.</li>

                                        <li> All disputes are subject to the exclusive jurisdiction of the courts of Mumbai, India.</li>

                                        <li>All applicable taxes and Limelight Lab Grown Diamonds Ltd. rules and regulations apply.</li>
                                    </ul>


                                    <HStack justifyContent='space-around' w='100%' mt='8'>

                                        <VStack spacing='2'>
                                            <Box w='200px' h='1px' bg='black' />
                                            <Text fontSize='10px'>Authorized Signature</Text>
                                            <Text fontSize='10px'>Date : {state.enrollment_date}</Text>
                                        </VStack>

                                        <VStack spacing='2'>
                                            <Box w='200px' h='1px' bg='black' />
                                            <Text fontSize='10px'>Customer Signature</Text>
                                            <Text fontSize='10px'>Date : {state.enrollment_date} </Text>
                                        </VStack>

                                    </HStack>

                                </VStack>
                            </Container>


                            {/* <Container
                                bg='white'
                                width='100%' maxW='100%'
                                style={{ height: '29.5cm' }}
                            >


                                <VStack spacing='3'>

                                    <Box w='100%' p='4' bg='#641344' alignItems='center' display='flex' justifyContent='center'>
                                        <HStack justifyContent='space-between' w='100%'>
                                            <Image border='0px solid #1c1c1c' alt='Logo' src='/logo-white.png' w='250px' />
                                            <Image border='0px solid #1c1c1c' alt='Logo' src='/logo2.png' w='250px' />
                                        </HStack>
                                    </Box>

                                    <VStack>

                                        <Text fontSize='4xl' fontFamily='customfont'>INVEST & SHINE ENROLLMENT FORM</Text>


                                    </VStack>

                                    <Card borderRadius='md' width={'100%'} bg='primary.800' colorScheme='primary'>
                                        <Text fontWeight='500' className='customfont' p={3} fontSize='lg' color={'white'}>Nominee Information</Text>
                                    </Card>

                                    <HStack w='100%'>
                                        <Text w='200px'>Name</Text>
                                        <Text>: {state.nominee_name}</Text>
                                    </HStack>

                                    <HStack w='100%'>
                                        <Text w='200px'>Relationship With Holder</Text>
                                        <Text>: {state.nominee_relationship}</Text>
                                    </HStack>


                                    <HStack w='100%'>
                                        <Text w='200px'>ID Prood</Text>
                                        <Text>: {state.nominee_id_proof}</Text>
                                    </HStack>

                                    <HStack w='100%'>
                                        <Text w='200px'>Id Proof Number</Text>
                                        <Text>: {state.nominee_id_no}</Text>
                                    </HStack>


                                    <HStack w='100%' justifyContent='start'>

                                        <Text className='customFont' fontSize='2xl' fontWeight='700'>Declaration</Text>

                                    </HStack>

                                    <HStack w='100%' justifyContent='start'>

                                        <Text fontSize='sm' >I hereby delcare that all the information provided above is true and accurate to the best of my knowledge.I understand and agree to abide by the terms and conditions of the INVEST & SHINE SCHEME as specified by Limelight Lab Grown Diamonds Limited.</Text>

                                    </HStack>

                                    <Box h='80px' />

                                    <HStack p='5' w='100%' justifyContent='space-between'>

                                        <VStack>
                                            <Box w='260px' h='2px' bg='primary.800' />
                                            <Text>Customer Signature</Text>
                                        </VStack>

                                        <VStack>
                                            <Box w='260px' h='2px' bg='primary.800' />
                                            <Text>Authorized Signature</Text>
                                        </VStack>

                                    </HStack>
                                </VStack>

                            </Container> */}

                        </VStack>
                    </Box>
                </VStack >
            </Container >
        </>
    )
}